import React from "react";


function Navbar() {
    return (
        <>
        <div className="navbar-custom">
            <div className="topbar container-fluid">
                <div className="d-flex align-items-center gap-lg-2 gap-1">
                    <div className="logo-topbar">
                        <a href="index.html" className="logo-light">
                            <span className="logo-lg">
                                <img src="assets/images/logo.png" alt="logo" />
                            </span>
                            <span className="logo-sm text-light">
                                <b>ETI</b>
                            </span>
                        </a>

                        <a href="index.html" className="logo-dark">
                            <span className="logo-lg">
                                <img src="assets/images/logo-dark.png" alt="dark logo" />
                            </span>
                            <span className="logo-sm text-light">
                                <b>ETI</b>
                            </span>
                        </a>
                    </div>

                    <button className="button-toggle-menu">
                        <i className="mdi mdi-menu"></i>
                    </button>

                    <button className="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
                <ul className="topbar-menu d-flex align-items-center gap-3">
                    <li className="dropdown">
                        <a className="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                            <span className="account-user-avatar">
                                <img src="assets/images/users/avatar-1.jpg" alt="user-image" width="32" className="rounded-circle" />
                            </span>
                            <span className="d-lg-flex flex-column gap-1 d-none">
                                <h5 className="my-0">Dominic Keller </h5>
                                <h6 className="my-0 fw-normal">Founder </h6>
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                            <div className=" dropdown-header noti-title">
                                <h6 className="text-overflow m-0">Welcome ! </h6>
                            </div>

                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className="mdi mdi-account-circle me-1"></i>
                                <span>My Account </span>
                            </a>

                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className="mdi mdi-account-edit me-1"></i>
                                <span>Settings </span>
                            </a>

                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className="mdi mdi-lifebuoy me-1"></i>
                                <span>Support </span>
                            </a>

                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className="mdi mdi-lock-outline me-1"></i>
                                <span>Lock Screen </span>
                            </a>

                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className="mdi mdi-logout me-1"></i>
                                <span>Logout </span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        </>
    );
}


export default Navbar;