import React from 'react';
import logo from './logo.svg';
import './App.css';
import Resources from "./components/Resources/ResourcesView";
import Navbar from './master-components/navbar';
// import Sidemenu from './master-components/side-menu';
import Footer from './master-components/footer';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Login from './components/Login/LoginView';
import HomeView from './components/Home/HomeView';
import Announcemtview from './components/Announcements/Announcementview';
import Contentview from './components/Content/Contentview';
import OrderResult from './components/OrderResult/OrderResult';
import PaymentGateway from './components/PaymentGateway/PaymentGateway';
import School from './components/SchoolProducts/SchoolProductsview';
// import Users from './components/users';
// import States from './components/state';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import District from './components/district';
function App() {
  return (
    <div className="App">
      <Router>
        
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" exact component={HomeView} />
                    <Route path="/home" component={HomeView}/> 
                    <Route path="/resources" component={Resources} /> 
                     <Route path="/announcement" component={Announcemtview} /> 
                     <Route path="/content" component={Contentview} /> 
                     <Route path="/paymentprocess" component={PaymentGateway} />
                     <Route path="/school-products" component={School} /> 
      <Route path="/orderresult/:transactionid" component={OrderResult} />
          <Route path="/">
            <div className="wrapper">
              <html lang="en" data-sidenav-size="condensed">
                {/* ... other attributes */}
              </html>

              {/* Header starts here... */}
              <Navbar />
              {/* <Sidemenu /> */}
              {/* Header ends here... */}

              {/* Footer starts here... */}
              <Footer />
              {/* Footer ends here... */}
            </div>
          </Route>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
