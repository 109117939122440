import AsyncSelect from "react-select/async";
import { Schoolmodel } from "./SchoolProductsmodel";
// import Container from "./DropdownContainer";
// import { TreePicker } from 'rsuite';
// import TreePicker from 'rsuite/TreePicker';
//import '../../../node_modules/rsuite/TreePicker/styles/index.less';
const data = [{ "parent_category_id": 1, "parent_category_name": "Sizes", "product_configurable": 1, "child_categories": "[{\"child_category_id\": 19, \"child_category_name\": \"update 17585\"},{\"child_category_id\": 18, \"child_category_name\": \"update 17\"}]" }, { "parent_category_id": 2, "parent_category_name": "Color", "product_configurable": 1, "child_categories": "[{\"child_category_id\": 3, \"child_category_name\": \"Description\"},{\"child_category_id\": 4, \"child_category_name\": \"Test Category\"}]" }, { "parent_category_id": 3, "parent_category_name": "Description", "product_configurable": 1, "child_categories": "[{\"child_category_id\": 8, \"child_category_name\": \"Description latest\"}]" }, { "parent_category_id": 16, "parent_category_name": "new category hyd", "product_configurable": 1, "child_categories": "" }, { "parent_category_id": 30, "parent_category_name": "Description latest 1255", "product_configurable": 1, "child_categories": "[{\"child_category_id\": 31, \"child_category_name\": \"USA\"}]" }, { "parent_category_id": 31, "parent_category_name": "USA", "product_configurable": 1, "child_categories": "[{\"child_category_id\": 32, \"child_category_name\": \"image\"}]" }, { "parent_category_id": 32, "parent_category_name": "image", "product_configurable": 1, "child_categories": "" }];

const School: React.FC = () => {
    const schoolmodel = Schoolmodel();

    return (
        <div  >
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active" onClick={schoolmodel.btnLogOutClick}>  <i className="mdi mdi-lock me-2 btn-logout" > logout</i> </li>
                                    </ol>
                                </div>
                                <a className="btn btn-danger back-btn" href="/">
                                    <i className="mdi mdi-chevron-left fs-normal"> Back </i></a>
                                <h4 className="page-title">School Products </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-2 mb-2 mb-sm-0">
                                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                        {schoolmodel.lstCategory && schoolmodel.lstCategory.map((item: any, index: number) => (
                                                            <a className={item.category_id == schoolmodel.activeCategory ? 'nav-link active' : 'nav-link'} id={`v-pills-${item.category_id}-tab`}
                                                                data-bs-toggle="pill" href={`v-pills-${item.category_id}`} role="tab" aria-controls={`v-pills-${item.category_id}-tab`} aria-selected="true"
                                                                onClick={(e) => schoolmodel.changeCategory(item)}>
                                                                <i className="mdi mdi-textbook-variant d-md-none d-block"></i>
                                                                <span className="">
                                                                    <img src={(item.category_image!=null &&  item.category_image!='' &&  typeof(item.category_image) != 'undefined') ?  ( schoolmodel.imagePath+item.category_image+'.webp') : ('./assets/images/no-image.png')  } 
                                                                        alt="" className="table-img" style={{'marginRight':'10px'}}/>
                                                                    {item.category_name}</span>
                                                            </a>
                                                        ))}

                                                    </div>
                                                </div>
                                                <div className="col-sm-10">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        {schoolmodel.lstCategory && schoolmodel.lstCategory.map((item: any, index: number) => (
                                                            <div className={item.category_id == schoolmodel.activeCategory ? 'tab-pane active show' : 'tab-pane '} id={`v-pills-${item.category_id}`} role="tabpanel" aria-labelledby="v-pills-textbooks-tab">
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-2">
                                                                        <h4 style={{'display':'contents'}}>{item.category_name}</h4>

                                                                        <button className="btn btn-primary float-end" onClick={schoolmodel.saveschoolProduct}>Save</button>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion" id="accordionExample1">

                                                                    {
                                                                        schoolmodel.lstClasses.length > 0 ?

                                                                            (schoolmodel.lstClasses.map((d: any, ind: number) => (
                                                                                <div className="accordion-item">
                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${d.id}`} aria-expanded="true" aria-controls="collapseTwo">
                                                                                        {d.classes}

                                                                                    </button>

                                                                                    
                                                                                    <div id={`${d.id}`} className={ind == 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby="headingfour" data-bs-parent="#accordionExample" >
                                                                                        <div className="accordion-body">
                                                                                            <div className="row">
                                                                                                <div className="mb-3 col-md-4">
                                                                                                    <label className="form-label">Product</label>
                                                                                                    <AsyncSelect
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        cacheOptions
                                                                                                        defaultOptions={schoolmodel.productsDrp}
                                                                                                        onChange={schoolmodel.handleProductChange}
                                                                                                        value={schoolmodel.productsDrp.length > 0 ? (schoolmodel.productsDrp.find((option: any) => option.value == schoolmodel.schoolProduct.product_id) || null) : null}
                                                                                                        loadOptions={schoolmodel.loadProduct}
                                                                                                    />

                                                                                                </div>
                                                                                                <div className="col-4">
                                                                                                    <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                                                    <button type="button" className="btn btn-danger" onClick={(e) => schoolmodel.addProduct(d.classes)}>+</button>

                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                {schoolmodel.lstSchoolProducts && schoolmodel.lstSchoolProducts.map((lsp: any, index: number) => (
                                                                                                    (lsp.product_name != '' && lsp.category_id == item.category_id && lsp.class == d.classes ?
                                                                                                        (<div className="col-md-1 mb-2">
                                                                                                            {/* ./assets/images/no-image.png */}
                                                                                                            {/* <img src={lsp.product_image =='' || lsp.product_image == null ? './assets/images/no-image.png' : schoolmodel.imagePath+lsp.product_image+'.webp' } className="mb-1 w-100 prd-img" /> */}

                                                                                                            {/* <img src={(lsp.product_image!=null &&  lsp.product_image!='' &&  typeof(lsp.product_image) != 'undefined') ?  ( schoolmodel.imagePath+lsp.product_image+'.webp') : ('./assets/images/no-image.png')} alt="" className="mb-1 w-100 prd-img"/> */}
                                                                                                            <img src={((lsp.product_image)!=null && (lsp.product_image)!='null' &&  lsp.product_image!='' &&  typeof(lsp.product_image) != 'undefined') ?  ( schoolmodel.imagePath+lsp.product_image+'.webp') : ('./assets/images/no-image.png')} alt="" className="mb-1 w-100 prd-img"/>
                                                                                                            <center>
                                                                                                                <p>{lsp.product_name}</p>
                                                                                                            </center>
                                                                                                            <center>
                                                                                                                <h3 className="m-0 text-denger" onClick={(e) => schoolmodel.spliceProduct(lsp)}>
                                                                                                                    <i className="mdi mdi-close-circle text-danger" ></i>
                                                                                                                </h3>
                                                                                                            </center>
                                                                                                        </div>) : ''
                                                                                                    )
                                                                                                ))
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )))
                                                                            :
                                                                            (
                                                                                <div className="row">
                                                                                    <div className="mb-3 col-md-4">
                                                                                        <label className="form-label">Product</label>
                                                                                        <AsyncSelect
                                                                                            isClearable
                                                                                            isSearchable
                                                                                            cacheOptions
                                                                                            defaultOptions={schoolmodel.productsDrp}
                                                                                            onChange={schoolmodel.handleProductChange}
                                                                                            value={schoolmodel.productsDrp.length > 0 ? (schoolmodel.productsDrp.find((option: any) => option.value == schoolmodel.schoolProduct.product_id) || null) : null}
                                                                                            loadOptions={schoolmodel.loadProduct}
                                                                                        />

                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                                        <button type="button" className="btn btn-danger" onClick={(e) => schoolmodel.addProduct('')}>+</button>

                                                                                        {/* <button className="btn btn-primary float-end" onClick={schoolmodel.saveschoolProduct}>Save</button> */}
                                                                                    </div>

                                                                                    {schoolmodel.lstSchoolProducts && schoolmodel.lstSchoolProducts.map((lsp: any, index: number) => (
                                                                                        (lsp.product_name != '' && lsp.category_id == item.category_id ?
                                                                                            (<div className="col-md-1 mb-2">
                                                                                                {/* <img src={lsp.product_image =='' || lsp.product_image == null ? './assets/images/no-image.png' : schoolmodel.imagePath+lsp.product_image+'.webp' } className="mb-1 w-100 prd-img" /> */}
                                                                                                <img src={((lsp.product_image)!=null && (lsp.product_image)!='null' &&  lsp.product_image!='' &&  typeof(lsp.product_image) != 'undefined') ?  ( schoolmodel.imagePath+lsp.product_image+'.webp') : ('./assets/images/no-image.png')} alt="" className="mb-1 w-100 prd-img"/>
                                                                                                <center>
                                                                                                    <p>{lsp.product_name}</p>
                                                                                                </center>
                                                                                                <center>
                                                                                                    <h3 className="m-0 text-denger" onClick={(e) => schoolmodel.spliceProduct(lsp)}>
                                                                                                        <i className="mdi mdi-close-circle text-danger" ></i>
                                                                                                    </h3>
                                                                                                </center>
                                                                                            </div>) : ''
                                                                                        )

                                                                                    ))
                                                                                    }



                                                                                </div>
                                                                            )
                                                                    }



                                                                    {/* <div className="accordion-item">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Class-2
                                                                        </button>
                                                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                                                            <div className="accordion-body">
                                                                                <div className="row">
                                                                                    <div className="mb-3 col-md-4">
                                                                                        <label className="form-label">Text
                                                                                            Books</label>
                                                                                        <select id="inputState" className="form-select">
                                                                                            <option>1</option>
                                                                                            <option>2</option>
                                                                                            <option>3</option>
                                                                                            <option>4</option>
                                                                                        </select>

                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                                        <button type="button" className="btn btn-danger">+</button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-1</p>
                                                                                        </center>
                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-2</p>
                                                                                        </center>
                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-3</p>
                                                                                        </center>
                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            Class-3
                                                                        </button>
                                                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                                                            <div className="accordion-body">
                                                                                <div className="row">
                                                                                    <div className="mb-3 col-md-4">
                                                                                        <label className="form-label">Text
                                                                                            Books</label>
                                                                                        <select id="inputState" className="form-select">
                                                                                            <option>1</option>
                                                                                            <option>2</option>
                                                                                            <option>3</option>
                                                                                            <option>4</option>
                                                                                        </select>

                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                                        <button type="button" className="btn btn-danger">+</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-1</p>
                                                                                        </center>
                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-2</p>
                                                                                        </center>
                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-2">
                                                                                        <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                                        <center>
                                                                                            <p>Text Book-3</p>
                                                                                        </center>

                                                                                        <center>
                                                                                            <h3 className="m-0 text-denger">
                                                                                                <i className="mdi mdi-close-circle text-danger"></i>
                                                                                            </h3>
                                                                                        </center>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        ))}


                                                        {
                                                        /* <div className="tab-pane fade" id="v-pills-stationsry" role="tabpanel" aria-labelledby="v-pills-stationsry-tab">
                                                            <div className="row">
                                                                <div className="mb-3 col-md-4">
                                                                    <h3>Stationary</h3>
                                                                    <select id="inputState" className="form-select">
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                    </select>

                                                                </div>
                                                                <div className="col-4">
                                                                    <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                    <button type="button" className="btn btn-danger">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-1</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-2</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-3</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-uniform" role="tabpanel" aria-labelledby="v-pills-uniform-tab">
                                                            <div className="row">
                                                                <div className="mb-3 col-md-4">
                                                                    <h3>Uniforms</h3>
                                                                    <select id="inputState" className="form-select">
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                    </select>

                                                                </div>
                                                                <div className="col-4">
                                                                    <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                    <button type="button" className="btn btn-danger">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-1</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-2</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-3</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane" id="v-pills-shoes" role="tabpanel" aria-labelledby="v-pills-shoes-tab">
                                                            <div className="row">
                                                                <div className="mb-3 col-md-4">
                                                                    <h3>Shoes</h3>
                                                                    <select id="inputState" className="form-select">
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                    </select>

                                                                </div>
                                                                <div className="col-4">
                                                                    <label style={{ 'visibility': 'hidden' }}></label> <br />
                                                                    <button type="button" className="btn btn-danger">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-1</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-2</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                                <div className="col-md-1 mb-2">
                                                                    <img src="./assets/images/users/avatar-1.jpg" className="mb-1 w-100 prd-img" />
                                                                    <center>
                                                                        <p>Text Book-3</p>
                                                                    </center>
                                                                    <center>
                                                                        <h3 className="m-0 text-denger">
                                                                            <i className="mdi mdi-close-circle text-danger"></i>
                                                                        </h3>
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default School;