import AsyncSelect from "react-select/async";
import { Resourceviewmodel } from "./ResourcesViewModel";

function ResourceView()
{
    const Resourceview = Resourceviewmodel();

    return (
        <div  >
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">eCommerce </a></li> */}
                                      
                                        <li className="breadcrumb-item active"  onClick={Resourceview.btnLogOutClick}>  <i className="mdi mdi-lock me-2 btn-logout" > logout</i> </li>
                                    </ol>
                                </div>
                                <a className="btn btn-danger back-btn"  href="/">
                                <i className="mdi mdi-chevron-left fs-normal"> Back </i></a>  
                                <h4 className="page-title">Resource </h4>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            
                                                {/* <AsyncSelect
                                                    isClearable
                                                    isSearchable
                                                    cacheOptions
                                                    defaultOptions={Resourceview.lstSchool}
                                                    onChange={Resourceview.handleSchoolChange}
                                                    value={Resourceview.lstSchool.find((option: any) => option.value == Resourceview.resource.school_id) || null}
                                                    loadOptions={Resourceview.loadSchool}
                                                /> */}

                                            </div>

                                            {/* <button className="btn btn-primary" onClick={Resourceview.getResources}>View</button> */}
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#resource-modal" onClick={Resourceview.addUser}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Resource
                                            </a>

                                            <a className="btn btn-primary mb-2 mx-3" onClick={()=>Resourceview.updateSequence()}>
                                                Update
                                            </a>

                                            {/* <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#resource-modal">
                                            <i className="mdi mdi-plus-circle me-2"></i> Add User
                                        </a> */}
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th></th>
                                                            <th>Banners/Gallery  Name</th>
                                                            <th>Type</th>
                                                            {/* <th>School Name</th> */}
                                                            <th>Sequence No</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Resourceview.filteredResources.map((d: any, index: number) => (
                                                            <tr key={d.resource_id}>
                                                                <td>{index + 1}</td>
                                                                <td><img src={Resourceview.imagePath+d.file_type+'.webp'} style={{width:"50px",height:"50px",borderRadius:"5px"}}/></td>
                                                                <td>{d.resource_name}</td>
                                                                <td>{d.resource_type}</td>
                                                                <td>
                                                                <input type="number" name="tsq_no" id="tsq_no" className="form-control" value={d.sq_no} 
                                                                onChange={(e:any)=>Resourceview.sequenceChange(d,e.target.value)} style={{width: "100px"}}/>    
                                                                </td>
                                                                {/* <td>{d.address}</td> */}
                                                                <td>
                                                                    <i className="uil uil-pen mx-2" data-bs-toggle="modal" data-bs-target="#resource-modal" onClick={() => Resourceview.editResource(d)}></i>
                                                                    <i className="uil uil-trash-alt" onClick={() =>Resourceview.deleteResource(d.resource_id)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="resource-modal" className="modal fade" aria-labelledby="standard-modalLabel"  data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={Resourceview.saveResource}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="resource-modalLabel">Add Banners/Gallery </h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={Resourceview.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <label >Names </label>
                                        <input type="text" name="resource_name" value={Resourceview.resource.resource_name} className="form-control" placeholder="Name" onChange={Resourceview.handleChange} />
                                        {(Resourceview.submit == true && Resourceview.resource.resource_name == '') ? (<div className="text-danger">Name required </div>) : ''}
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label > Type</label>
                                        <select name="resource_type" className="form-control" value={Resourceview.resource.resource_type} onChange={Resourceview.handleChange}>
                                            <option value="" selected aria-readonly disabled>Select</option>
                                            <option value="Gallery">Gallery</option>
                                            <option value="Banners">Banners</option>
                                        </select>
                                        {(Resourceview.submit == true && Resourceview.resource.resource_type == '') ? (<div className="text-danger">Name required </div>) : ''}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label >SQ no</label>
                                        <input type="number" name="sq_no" value={Resourceview.resource.sq_no} className="form-control" placeholder="SQ no" onChange={Resourceview.handleChange} />
                                        {/* {<div className="text-danger">{resource.sq_no}</div>} */}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label >File <span className="text-danger">Max size 3 Mb</span></label>
                                        {/* <input type="file" name="file_type" value={resource.file_type} className="form-control" placeholder="file" onChange={handleChange} /> */}
                                        <input type="file" id="file_type" ref={Resourceview.fileInputRef}   accept="image/*"  className="form-control" placeholder="file" onChange={Resourceview.handleImageChange} />
                                        {(Resourceview.submit == true && Resourceview.resource.file_type == '') ? (<div className="text-danger">File required </div>) : ''}
                                        
                                    </div> 
                                     <div className="col-md-12 mb-2">
                                    {Resourceview.imageSrc && <img src={Resourceview.imageSrc} className="w-100" style={{height:'350px',objectFit: 'contain'}} alt="Selected File" />}
                                    { Resourceview.resource.file_type!=''  && !Resourceview.imageSrc?  <img src={Resourceview.imagePath+Resourceview.resource.file_type+'.webp'} className="w-100" style={{height:'350px',objectFit: 'contain'}} alt="Selected File" />:''}
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <label >School</label>
                                        <AsyncSelect
                                            isClearable
                                            isSearchable
                                            cacheOptions
                                            defaultOptions={Resourceview.lstSchool}
                                            onChange={Resourceview.handleSchoolChange}
                                            value={Resourceview.lstSchool.find((option: any) => option.value == Resourceview.resource.school_id) || null}
                                            loadOptions={Resourceview.loadSchool}
                                        />
                                        {(Resourceview.submit == true && Resourceview.resource.school_id == 0) ? (<div className="text-danger">School required </div>) : ''}
                                    </div> */}

                                    
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={Resourceview.cancel}>Close </button>
                                <button type="submit" className="btn btn-primary" onClick={(e:any)=>Resourceview.saveResource(e)}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
    
}


export default ResourceView;