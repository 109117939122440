import { Children, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useHistory } from "react-router-dom";

interface OptionType {
    label: string;
    value: string;
    children: [];
}


export function Schoolmodel() {
    const dispatch = useDispatch();

    const [productsDrp, setProductsDrp] = useState<{ label: string; value: string }[] | []>([]);

    const [products, setProducts] = useState([{
        product_details_id: 0,
        product_id: 0,
        title: "",
        sku_id: "",
        status: "",
        mrp: "",
        selling_price: "",
        dispatched_days: 0,
        stock: 0,
        notify_qty: "",
        height: "",
        length: "",
        bradth: "",
        weight: "",
        pack_qty: 0,
        local_delivery_charges: "",
        zonal_delivery_charges: "",
        national_delivery_charges: "",
        notes: "",
        updated_date: "",
        brand_id: 0,
        brand_name: "",
        category_id: 0,
        category_name: "",
        image: ''
    }])


    const [schoolLoginInfo, setSchoolLoginInfo] = useState({ address: "", contact_no: "", email: " ", image: null, school_id: 0, school_name: "0", status: null, udise_code: "" });
    const navigate = useHistory();
    const schoolRepository = SchoolRepository();

    const imagePath = schoolRepository.ecomm_imagePath;

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [activeCategory, setactiveCategory] = useState(0);

    const [lstCategory, setCategorys] = useState([{
        // category_fields : '',
        // category_id : 0,
        // category_image : '',
        // category_name : '',
        // category_variants : '',
        // parent_category_id : 0,
        // parent_category_name : '',
        // updated_date : '',
        // is_class_configurable : 0
        category_id: 0,
        parent_category_id: 0,
        category_name: '',
        category_image: '',
        product_configurable: 0,
        updated_date: '',
        is_class_configurable: 0,
    }])

    const [lstSchoolProducts, setSchoolProducts] = useState([{
        school_product_id: 0,
        product_id: 0,
        product_name: '',
        school_id: 0,
        category_id: 0,
        category_name: '',
        brand_id: 0,
        brand_name: '',
        product_image: '',
        updated_date: '',
        SchoolProductscol: '',
        class: '',
    }])

    const [schoolProduct, setSchoolProduct] = useState({
        school_product_id: 0,
        product_id: 0,
        product_name: '',
        school_id: 0,
        category_id: 0,
        category_name: '',
        brand_id: 0,
        brand_name: '',
        product_image: '',
        updated_date: '',
        SchoolProductscol: '',
        class: '',
    })


    const [lstClasses, setClasses] = useState([{
        classes: '',
        class: '',
        image: '',
    }])


    // const [lstCategoryDrp, setCategoryDrp] = useState<{ label: string; value: string }[] | []>([]);

    const [lstCategoryDrp, setCategoryDrp] = useState<OptionType[]>([]);
    const [lstProductDrp, setProductDrp] = useState<{ label: string; value: string }[] | []>([]);
    const [lstBrand, setBrands] = useState<{ label: string; value: string }[] | []>([]);



    const [validate, setValidate] = useState({
        product_id: 0,
        product_name: '',
        school_id: 0,
        category_id: 0,
        category_name: '',
        brand_id: 0,
        brand_name: '',
        product_image: '',
        updated_date: '',
    })
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredCategory = lstCategory.filter((category: any) =>
            category.category_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setCategorys(filteredCategory);
        setSearchTerm(e.target.value);

        if (e.target.value == '') {
            debugger;
            getCategory();
        }
    };

    const changeCategory = (e: any) => {
        debugger;
        getSchoolProducts();
        setactiveCategory(e.category_id);
        getproductsbycategory(e.category_id);
        
        if (e.is_class_configurable == 1) {
            debugger;
            getClassesBySchool();
        } else {
            setClasses([])
        }

    }


    const cancel = () => {
        debugger;
        setSchoolProduct({
            school_product_id: 0,
            product_id: 0,
            product_name: '',
            school_id: 0,
            category_id: 0,
            category_name: '',
            brand_id: 0,
            brand_name: '',
            product_image: '',
            updated_date: '',
            SchoolProductscol: '',
            class: '',
        })

        setValidate({
            product_id: 0,
            product_name: '',
            school_id: 0,
            category_id: 0,
            category_name: '',
            brand_id: 0,
            brand_name: '',
            product_image: '',
            updated_date: '',
        })

        setImageSrc('');
    }
    const userStoredData = 
    useSelector((state: AppState) =>
{
  
  

  return  typeof(state.storeData.SchoolLogin)!='undefined'?state.storeData.SchoolLogin:null;
}
);
    
    // useSelector((state: AppState) => {
    //     //    / debugger; 
    //     return state.storeData.viewName == 'SchoolLogin' ? state.storeData.storeData : null;
    // });


    const btnLogOutClick = () => {
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData: {},
                viewName: 'SchoolLogin',
            },
        });

        navigate.push('/login');
    }

    useEffect(() => {
        debugger;
        try {
            if (typeof (userStoredData.school_id) == 'undefined' || (+userStoredData.school_id == 0)) {
                navigate.push('/login');
            }
        } catch (e) {
            navigate.push('/login');
        }
        setSchoolLoginInfo(userStoredData);
        schoolLoginInfo.school_id = userStoredData.school_id;
        getSchoolProducts();
        getCategory();
        getBrands();
    }, []);




    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setSchoolProduct((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setSchoolProduct((prev) => ({ ...prev, [name]: value }));
        }
    }


    const saveschoolProduct = async () => {
        debugger;

        // e: React.FormEvent<HTMLFormElement>
        // e.preventDefault();

        if(lstSchoolProducts.length > 0){
            debugger;
            let ind = lstSchoolProducts.findIndex((x:any)=> x.product_name == '');
            if(ind == 0 || ind > 0){
                lstSchoolProducts.splice(ind,1);
            }
            
        }


        // if (Object.values(newValidate).every((error) => !error)) {
        try {
            debugger;
            // schoolProduct.school_id=schoolLoginInfo.school_id;
            const responseData: any = await schoolRepository.saveProduct(lstSchoolProducts);
            if (responseData[0].db_result > 0) {
                cancel();
                const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                if (buttonElement) {
                    buttonElement.click();
                }
                // if(file){
                //     await uploadImage();
                // }
                // getSchoolProducts()
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } else {
                debugger;
                if (responseData[0].db_result == -3) {
                    Swal.fire("Record exists", "Record exists", "warning");
                }
                else {
                    Swal.fire("Failed", "Failed", "error");
                }
            }
        } catch (error) {
        }
        // } 
        // else {
        //     setValidate(newValidate);
        // }
    }




    // ecommerce 
    const getCategory = async () => {
        debugger;
        try {
            debugger;
            const lstCategory: any = await schoolRepository.getCategories();
            // let data = lstCategory.filter((x:any)=>x.product_configurable == 1);
            debugger;
            if (lstCategory.length > 0) {
                debugger;
                setCategorys(lstCategory);
                setactiveCategory(lstCategory[0].category_id);
            }
            debugger;
            if (lstCategory[0].is_class_configurable == 1) {
                debugger;
                getClassesBySchool();
                getproductsbycategory(lstCategory[0].category_id);
            }

            debugger;
            // setCategorys(
            //     data
            // )
            // const options: { label: string; value: string }[] = data.map((item: any) => ({
            //     label: item.category_name,
            //     value: item.category_id.toString(),
            // }));
            // setCategoryDrp(options)

            // const formattedOptions = formatOptions(data);
            // setCategoryDrp(formattedOptions);

            // console.log('lstCategoryDrp' , lstCategoryDrp)
        }
        catch (error) {
            setCategorys(
                []
            )
        }
    }

    const formatOptions = (data: any[]): OptionType[] => {
        return data.map((item) => ({
            label: item.parent_category_name,
            value: item.parent_category_id.toString(),
            children: item.child_categories
                ? JSON.parse(item.child_categories).map((child: any) => ({
                    label: child.child_category_name,
                    value: child.child_category_id.toString(),
                }))
                : undefined,
        }));
    };

    const loadOptions = (inputValue: string, callback: (options: OptionType[]) => void) => {
        // Filter options based on user input
        const filteredOptions = lstCategoryDrp.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        // Call the callback with the filtered options
        callback(filteredOptions);
    };

    const getBrands = async () => {
        try {
            debugger;
            const data: any = await schoolRepository.getBrands();

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.brand_name,
                value: item.brand_id.toString(),
            }));
            setBrands(options)

        }
        catch (error) {
            console.log('Something went wrong', error);
        }
    }

    const getProducts = async () => {
        debugger;
        try {
            debugger;
            const data: any = await schoolRepository.getProducts(schoolProduct);
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.title,
                value: item.product_id.toString(),
            }));
            setProductDrp(options)
        }
        catch (error) {
            console.log('Something went wrong', error);
        }
    }

    const getSchoolProducts = async () => {
        debugger;
        try {
            debugger;
            let school_id = ({ 'school_id': userStoredData.school_id })
            const data: any = await schoolRepository.getSchoolProducts(school_id);
            debugger;
            if(data.length > 0) {
                setSchoolProducts(data);
            }
            
        }
        catch (error) {
            console.log('Something went wrong', error);
        }
    }

    const getClassesBySchool = async () => {
        debugger;
        try {
            debugger;
            let school_id = ({ 'school_id': userStoredData.school_id })
            const data: any = await schoolRepository.getClassesBySchool(school_id);
            debugger;
            setClasses(data);
        }
        catch (error) {
            console.log('Something went wrong', error);
        }
    }

    const getproductsbycategory = async (categoryId: any) => {
        debugger;
        try {
            debugger;
            let category_id = ({ 'category_id': categoryId })
            const data: any = await schoolRepository.getproductsbycategory(category_id);
            debugger;

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.title,
                value: item.product_details_id.toString(),
            }));
            debugger;
            setProductsDrp(options);
            setProducts(data);
            // setClasses(data);
        }
        catch (error) {
            console.log('Something went wrong', error);
        }
    }

    // const handleCategoryChange = (selectedOption: any | null) => {
    //     debugger;

    //     let id = '';
    //     let name = '';
    //     // let data = lstCategoryDrp.filter((x:any)=> x.value == selectedOption);
    //     debugger;
    //     for(let i=0; i<lstCategoryDrp.length;i++){
    //         debugger;
    //         if(lstCategoryDrp[i].children!=undefined && lstCategoryDrp[i].children.length>0){
    //             debugger;
    //             let myArray :any = lstCategoryDrp[i].children;
    //             for(let j=0;j<myArray.length;j++){
    //                debugger;
    //                if(myArray[j].value == selectedOption){
    //                 debugger;
    //                 name = lstCategoryDrp[i].label;
    //                 id = lstCategoryDrp[i].value;
    //                 break;
    //                }
    //             }
    //             // break;
    //         }
    //         if(lstCategoryDrp[i].value == selectedOption){
    //             debugger;
    //             name = lstCategoryDrp[i].label;
    //             id = lstCategoryDrp[i].value;
    //             break;
    //         }
    //     }

    //     debugger;
    //     setSchoolProduct(prevcategoryid => ({
    //         ...prevcategoryid,
    //         category_id: selectedOption ? (+id) : 0,
    //         category_name: selectedOption ? name : '',
    //     }));

    //     if((+selectedOption)>0 && schoolProduct.brand_id > 0){
    //         debugger;
    //         schoolProduct.category_id = (+id)
    //         getProducts()
    //     }
    // };

    const loadCategory = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterCategory(inputValue);
        callback(filteredOptions);
    };

    const filterCategory = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstCategoryDrp.filter((option: any) => {
                debugger;
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstCategoryDrp;
        }
    };


    // const handleBrandChange = (selectedOption: any | null) => {
    //     debugger;
    //     setSchoolProduct(prevbrandid => ({
    //         ...prevbrandid,
    //         brand_id: selectedOption ? selectedOption.value : '',
    //         brand_name: selectedOption ? selectedOption.label : '',
    //     }));
    //     if(schoolProduct.category_id >0 && (+selectedOption.value) > 0){
    //         debugger;
    //         schoolProduct.brand_id = selectedOption.value;
    //         getProducts()
    //     }
    // };

    const loadBrand = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterBrand(inputValue);
        callback(filteredOptions);
    };

    const filterBrand = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstBrand.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstBrand;
        }
    };

    const handleProductChange = (selectedOption: any | null) => {
        debugger;
        setSchoolProduct(prevbrandid => ({
            ...prevbrandid,
            product_id: selectedOption ? selectedOption.value : '',
            product_name: selectedOption ? selectedOption.label : '',
        }));
    };

    const loadProduct = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterProduct(inputValue);
        callback(filteredOptions);
    };

    const filterProduct = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return productsDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return productsDrp;
        }
    };


    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                debugger;
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    debugger;
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };


    // const uploadImage = async () => {
    //     debugger;
    //     if (file) {
    //         debugger;
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('imageToSave', schoolProduct.product_image);
    //         try {
    //             debugger;
    //             const responseData: any = await schoolRepository.uploadImage(formData);
    //             return responseData;
    //         }
    //         catch (error) {
    //             console.error('Error uploading image:', error);
    //         }
    //     }
    // }


    const editProduct = (d: any) => {
        debugger;
        getProducts();
    }


    const addProduct = async (className:any) => {
        debugger;
        let id = schoolProduct.product_id;

        debugger;
        for(let i=0;i<products.length;i++){
            debugger;
            if(products[i].product_details_id == id){
                schoolProduct.school_product_id = 0 ;
                schoolProduct.product_id = products[i].product_id;
                schoolProduct.product_name = products[i].title;
                schoolProduct.school_id = userStoredData.school_id;
                schoolProduct.category_id = products[i].category_id;
                schoolProduct.category_name = products[i].category_name;
                schoolProduct.brand_id = products[i].brand_id;
                schoolProduct.brand_name = products[i].brand_name;
                schoolProduct.product_image = products[i].image;
                schoolProduct.updated_date = '';
                schoolProduct.SchoolProductscol = '' ;
                schoolProduct.class = className;

                setSchoolProducts((prevProducts) => [...prevProducts, schoolProduct]);
                break;
            }
        }

    }

    const spliceProduct = async(e:any) =>{
        debugger;
         let ind = lstSchoolProducts.findIndex((x:any)=>x.product_name == e.product_name && x.category_id == e.category_id);
         debugger;
         lstSchoolProducts.splice(ind,1);
        //  setSchoolProducts(lstSchoolProducts);

         setSchoolProducts([...lstSchoolProducts]);
    }


    const deleteProduct = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await schoolRepository.deleteProduct({ 'school_product_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getProducts();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    return {
        btnLogOutClick, saveschoolProduct, getCategory, handleChange, cancel, handleSearch,
        searchTerm, validate, schoolProduct, lstCategory, lstCategoryDrp, lstProductDrp,

        // handleCategoryChange , 
        loadCategory, lstBrand, getBrands,
        file, setFile,
        imageSrc, setImageSrc, handleImageChange,
        // handleBrandChange,
        loadBrand,
        // uploadImage,
        getProducts,
        handleProductChange, loadProduct,
        fileInputRef,
        lstSchoolProducts,
        imagePath,
        editProduct,
        deleteProduct,
        loadOptions,
        activeCategory,
        changeCategory,
        lstClasses,
        productsDrp,
        addProduct,
        spliceProduct
        // updateSequence,
    }
}