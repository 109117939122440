import { useEffect, useState } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { useHistory  } from 'react-router-dom';


import { ActionType, AppState } from "../../store/Page.Actions";
export function LoginViewModel()
{
    const dispatch = useDispatch();
    const navigate = useHistory();
const [loginDetails,setLoginDetails]=useState({email:'',password:''});
const [submitted,setSubmitted]=useState(false);
const [isEmailValid,setEmailValid]=useState(false);
const [rememberData,setRememberData]=useState(false);

const  repository=SchoolRepository();

const userStoredData = 
useSelector((state: AppState) =>
{
  
  

  return  typeof(state.storeData.SchoolLoginStored)!='undefined'?state.storeData.SchoolLoginStored:null;
}
);


// useSelector((state: AppState) =>
// {
// //    / debugger; 
//   return  state.storeData.viewName=='SchoolLoginStored'?state.storeData.storeData:null;
// }
// );
const onRememberChange=(checked:boolean)=>
{
debugger;
    setRememberData(checked);
  

    let storedData={email:loginDetails.email,password:loginDetails.password};
  if(loginDetails.email!='' &&  loginDetails.password!='' && checked)
    {
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:storedData,
            viewName:'SchoolLoginStored',
        },
      });
    }else{
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData:{},
                viewName:'SchoolLoginStored',
            },
          });

    }

 

}
useEffect(()=>{
const assignStoredData=()=>
{
debugger;
try{
if(userStoredData!= null && typeof( userStoredData.email)!='undefined' &&  userStoredData.email!=''&&  userStoredData.password!='')
{
    setLoginDetails({email:userStoredData.email,password:userStoredData.password});
    setRememberData(true);
}
}catch(e)
{

}

}



assignStoredData();

},[]);

const validateEmail=(name:any, value:any )=>
{
    if(name=='email')
    {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailValid( emailRegex.test(value));

    }
}

const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if ('value' in e.target) {
        const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
        validateEmail( name, value);
        setLoginDetails((prev) => ({ ...prev, [name]: value }));
         
    } else {
        const { name, value } = e.target as HTMLSelectElement;
        setLoginDetails((prev) => ({ ...prev, [name]: value }));
    }
}
const btnLoginClick = async (e: React.FormEvent) => {
    debugger;
    e.preventDefault();

    setSubmitted(true);

if(isEmailValid && loginDetails.email!='' &&  loginDetails.password!='' )
{
debugger;
   let dbResult=await repository.schoolLogin(loginDetails);

if(dbResult.length>0 && (+dbResult[0].school_id)>0)
{
   dispatch({
    type: ActionType.STORE,
    payload: {
        storeData:dbResult[0],
        viewName:'SchoolLogin',
    },
  });

navigate.push('/home');
}

   debugger;
}



}
    return {btnLoginClick,handleChange,loginDetails,submitted,isEmailValid,onRememberChange,rememberData}
}