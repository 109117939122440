import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useHistory } from "react-router-dom";

export function Announcementviewmodel() {
    const dispatch = useDispatch();
    const [schoolLoginInfo,setSchoolLoginInfo]=useState({address : "" ,contact_no : "", email : " " ,image : null, school_id : 0, school_name : "0", status : null, udise_code : ""});
    const navigate = useHistory();
    const schoolRepository = SchoolRepository();

    const [lstAnnouncemts, setAnnouncemts] = useState({
        data: []
    })

    const [lstFilterAnnouncements, setFilterAnnouncements] = useState(
        []
    )

    const [announcemnts, setannouncemnts] = useState({
        announcements_id: 0,
        information: '',
        school_id:  schoolLoginInfo.school_id ,
        updated_date: ''
    })

    const [validate, setValidate] = useState({
        announcements_id: 0,
        information: '',
        school_id: 0,
        updated_date: ''
    })
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e: any) => {
        debugger;
        let filteredannounce = lstAnnouncemts.data.filter((Announcemnts: any) =>
        Announcemnts.information.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterAnnouncements(filteredannounce);
        setSearchTerm(e.target.value);
    };
    const filteredannounce = lstFilterAnnouncements.slice();

    const cancel = () => {
        debugger;

        setannouncemnts({
            announcements_id: 0,
            information: '',
            school_id: 0,
            updated_date: ''
        })

        setValidate({
            announcements_id: 0,
            information: '',
            school_id: 0,
            updated_date: ''
        })


    }
    const userStoredData = useSelector((state: AppState) =>
    {
    //    / debugger; 
      return  state.storeData.viewName=='SchoolLogin'?state.storeData.storeData:null;
    });
    
    
    const btnLogOutClick=()=>{
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData:{},
                viewName:'SchoolLogin',
            },
          });
        
        navigate.push('/login');
    }

    useEffect(() => {
        //debugger;
        // getCities();
        try{
            if(typeof (userStoredData.school_id)=='undefined' || (+userStoredData.school_id==0))
            {
                navigate.push('/login');
            }}catch(e)
            {
                navigate.push('/login');
            }
        setSchoolLoginInfo(userStoredData);
        schoolLoginInfo.school_id=userStoredData.school_id;
        getAnnouncements();

    }, []);




    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setannouncemnts((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setannouncemnts((prev) => ({ ...prev, [name]: value }));
        }
    }


    const saveAnnouncemts = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!announcemnts.information.trim()) {
            newValidate.information = "Announcements  required"
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                announcemnts.school_id=schoolLoginInfo.school_id;
                const responseData: any = await schoolRepository.saveAnnouncement(announcemnts);

                if (responseData[0].db_result > 0) {
                    cancel();

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }
                    getAnnouncements();
                    //getCities()
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }


    const getAnnouncements = async () => {
        setFilterAnnouncements([]);
        debugger;
        try {
            const data: any = await schoolRepository.getAnnouncements({ 'school_id': schoolLoginInfo.school_id });

            setAnnouncemts({
                data

            })
            setFilterAnnouncements(data)
            debugger;

        }
        catch (error) {
            setAnnouncemts({
                data: []
            })
        }
    }

    const editAnnouncement = (e: any) => {
        debugger;
        setannouncemnts(e);
    }

    const deleteannouncement = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {

            
            debugger;
            
            const responseData :any= schoolRepository.deleteAnnouncement({ 'announcements_id': e });
            console.log(responseData)
            Swal.fire("Record Deleted successfully", "", "info");
           getAnnouncements();
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    return {btnLogOutClick, saveAnnouncemts, getAnnouncements,deleteannouncement, handleChange,cancel,editAnnouncement,handleSearch, filteredannounce,searchTerm, validate, announcemnts }
}

 