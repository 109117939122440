import AsyncSelect from "react-select/async";
import { HomeViewModel } from "./HomeViewModel";
import '../Home/custom.css'
 
import ReactQuill from "react-quill";
const HomeView: React.FC = () => {

    const viewModel = HomeViewModel();

    return (

        <>
         
            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }} // Hide the input visually
                ref={viewModel.fileInputRef}
                onChange={viewModel.handleImageChange}
            />
            <div className="container mt-5">
            <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active" onClick={viewModel.btnLogOutClick}>  <i className="mdi mdi-lock me-2 btn-logout" > logout</i> </li>
                                    </ol>
                                    
                                  

                                </div>

                                <div style={ (+viewModel.schoolLoginInfo.validity)>0 ? {display:"none"}: {textAlign:"left",display:"block"}} >
                                    <span style={{color:"red"}}>{ (+viewModel.schoolLoginInfo.validity)<=0 ? 'School Validity Expaired':'' }</span>
                                
                                    <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item active" onClick={viewModel.btnRenualClick}>  <i className="mdi  me-2 btn-renual" > Renual now</i> </li>
                                    </ol>
                                    
                                  

                                </div>

                                
                            </div>
                        </div>
                    </div>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="card widget-flat bx-shdw">
                            <div className="card-body crd p-0">
                                
                                    <div className="col-md-12 bg-color">
                                    <span className="edit-icon" onClick={() => viewModel.changeImage()}><i className="uil-pen"></i></span>
                                        <img src={`${viewModel.imagePath}${viewModel.schoolLoginInfo.image}.webp?${new Date().getTime()}`}
                                            // src={viewModel.imagePath+viewModel.schoolLoginInfo.image+'.webp'}
                                            
                                            alt="" className="scl-img-lst rounded-20" />


                                    </div>
                                    <div className="col-md-12 p-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="text-capitalize fw-500 mb-2 text-center mt-88">{viewModel.schoolLoginInfo.school_name} <i className="fa fa-heart-o float-end f-20 text-danger" aria-hidden="true"></i></h3>
                                                <h4 className="text-muted fw-normal text-center">{viewModel.schoolLoginInfo.udise_code}</h4>
                                                <h4 className="text-muted fw-normal">{viewModel.schoolLoginInfo.status}</h4>

                                            </div>
                                           
                                            <div className="col-md-6 mb-2">
                                            <button className="btn btn-outline-danger brd-11 cstm-btn w-100" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => viewModel.getSchoolDetails()}> View / Edit</button>
                                            <div className="clearfix"></div>
                                            </div>
                                            <div className="col-md-6 mb-2">

                                            <button className="btn btn-outline-danger brd-11 cstm-btn mr-b-3 w-100" onClick={() => viewModel.btnProducts()}>Products</button>
                                            <div className="clearfix"></div> 

                                            </div>
                                            <div className="col-md-6">
                                            <button className="btn btn-outline-danger brd-11 cstm-btn mr-b-3 w-100" style={{    margin: "2px"}} data-bs-toggle="modal" data-bs-target="#social-fees" onClick={() => viewModel.getSchoolFees()}>Fees</button>
                                            
                                            <div className="clearfix"></div>
                                            </div>
                                            <div className="col-md-6 text-center">
                                            <div className="clearfix"></div>
                                            <button className="btn btn-outline-danger brd-11 cstm-btn mr-b-3 w-100" data-bs-toggle="modal" data-bs-target="#social-media" onClick={() => viewModel.getSchoolDetails()}>Social Media</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card widget-flat bx-shdw">
                            <div className="card-body crd p-0">
                                    <div className="col-md-12 text-center">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPdBIVIX0Y03mP-o23D7pliSRjhbxSFUDnY4_xAmpGc3xfqLnpkajZKzl-JvxSoRno4Xw&usqp=CAU" alt="" className="bg-img h-245" />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="text-capitalize fw-500 mb-2 text-center mt-46">Banners/Gallery  <i className="fa fa-heart-o float-end f-20 text-danger" aria-hidden="true"></i></h3>
                                            </div>
                                            {/* data-bs-toggle="modal" data-bs-target="#rsource-modal"   */}
                                            <div className="col-md-12 text-center mt-5rem mb-3"><button className="btn btn-outline-danger brd-11 cstm-btn mt-3 mb-1"   
                                            onClick={viewModel.btnResorceClick} 
                                             >View / Edit</button></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card widget-flat bx-shdw">
                            <div className="card-body crd p-0">
                                    <div className="col-md-12 text-center">
                                        <img src="https://st2.depositphotos.com/1832477/11780/i/450/depositphotos_117809714-stock-illustration-front-view-school-building-cartoon.jpg" alt="" className="bg-img" />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="text-capitalize fw-500 mb-2 text-center mt-88">Content  <i className="fa fa-heart-o float-end f-20 text-danger" aria-hidden="true"></i></h3>
                                            </div>
                                            <div className="col-md-12 text-center mt-5rem mb-3"><button 
                                            // data-bs-toggle="modal" data-bs-target="#about-modal"
                                            
                                            className="btn btn-outline-danger brd-11 cstm-btn mt-3 mb-1" onClick={() => viewModel.btnContentClick()}>View / Edit</button></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card widget-flat bx-shdw">
                            <div className="card-body crd p-0">
                                    <div className="col-md-12 text-center">
                                        <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/COVER-IMAGE_Digital-Selling-Foundation-Program.jpg" alt="" className="bg-img" />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="text-capitalize fw-500 mb-2 text-center mt-88">Announcement  <i className="fa fa-heart-o float-end f-20 text-danger" aria-hidden="true"></i></h3>
                                            </div>
                                            <div className="col-md-12 text-center mt-5rem mb-3"><button 
                                            onClick={() => viewModel.btnAnnouncementClick()}
                                            className="btn btn-outline-danger brd-11 cstm-btn mt-3 mb-1">View / Edit</button></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div id="rsource-modal" className="modal fade " aria-labelledby="rsource-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width" style={{ maxWidth: '1300px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="rsource-modalLabel" >Add schools </h4>
                            <button type="button" className="btn-close float-end" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={viewModel.cancel}></button>
                        </div>
                        <div className="modal-body">
<ResourceView/>

                        </div>
                        </div>
                        </div>

                        </div> */}
            <div id="standard-modal" className="modal fade " aria-labelledby="standard-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width" style={{ maxWidth: '1300px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel" >Edit schools </h4>
                            <button type="button" className="btn-close float-end" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={viewModel.cancel}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="basicwizard">
                                        <ul className="nav nav-pills nav-justified form-wizard-header mb-4" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolInfo" */}
                                                <a className={viewModel.activeTab == 'schoolInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="true" role="tab">
                                                    <i className="uil uil-building font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">School information</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#viewModel.addressInfo" */}
                                                <a className={viewModel.activeTab == 'addressInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-location-point font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Address / Contact information</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#resourceInfo"  */}
                                                <a className={viewModel.activeTab == 'resourceInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-sync font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Resource details</span>
                                                </a>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                {/* href="#viewModel.facilityInfo"  */}
                                                <a className={viewModel.activeTab == 'facilityInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-game-structure font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Facility details</span>
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="tab-content b-0 mb-0">
                                            <div className={viewModel.activeTab == 'schoolInfo' ? 'tab-pane active show' : 'tab-pane'} id="schoolInfo" role="tabpanel">
                                                <div className="row">

                                                    <div className="col-md-3 mb-2">
                                                        <label >UDISE Code</label>
                                                        <input type="text" name="udise_code" value={viewModel.schoolInfo.udise_code} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)} />
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.udise_code == '') ? (<div className="text-danger">UDISE code required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >School Name</label>
                                                        <input type="text" className="form-control"  name="school_name" value={viewModel.schoolInfo.school_name}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)} />
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.school_name == '') ? (<div className="text-danger">School name required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Instruction Medium</label>
                                                        <input type="text" name="instruction_medium" value={viewModel.schoolInfo.instruction_medium} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)} />
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.instruction_medium == '') ? (<div className="text-danger">Instruction required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Status</label>
                                                        <select name="status" value={viewModel.schoolInfo.status} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Active">Approve</option>
                                                            <option value="Blocked">Block</option>
                                                            <option value="Deleted">Inactive</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >School Type</label>

                                                        <select name="school_type" value={viewModel.schoolInfo.school_type} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Co-educational">Co-educational</option>
                                                            <option value="Girls Only">Girls Only</option>
                                                            <option value="Boys Only">Boys Only</option>
                                                        </select>
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.school_type == '') ? (<div className="text-danger">School type required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Classes</label>

                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={viewModel.schoolClasses}
                                                            onChange={viewModel.handleSelectChange}
                                                            value={viewModel.schoolClasses.find((option: any) => option.value === viewModel.schoolInfo.classes) || null}
                                                            loadOptions={viewModel.loadSchoolClasses}
                                                        />
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.classes == '') ? (<div className="text-danger">School type required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Year of Establishment</label>
                                                        <input type="number" name="year_of_establishment" value={viewModel.schoolInfo.year_of_establishment} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)} />
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >School Area</label>
                                                        <select name="school_area" value={viewModel.schoolInfo.school_area} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Urban">Urban</option>
                                                            <option value="Semi-Urban">Semi-Urban</option>
                                                            <option value="Rural">Rural</option>
                                                        </select>
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.school_area == '') ? (<div className="text-danger">School area required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >School Shifted to New Place</label>
                                                        <select name="school_shifted_to_new_place" value={viewModel.schoolInfo.school_shifted_to_new_place} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>

                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Management</label>
                                                        <select name="management" value={viewModel.schoolInfo.management} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="PVT">PVT</option>
                                                            <option value="Unaided">Unaided</option>
                                                            <option value="Aided">Aided</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Building</label>
                                                        <select name="building" value={viewModel.schoolInfo.building} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Owned">Owned</option>
                                                            <option value="Government">Government</option>
                                                            <option value="Rented">Rented</option>
                                                        </select>
                                                    </div>





                                                    <div className="col-md-3 mb-2">
                                                        <label >Pre Primary Section Available </label>
                                                        <select name="pre_primary_sectin_avilable" value={viewModel.schoolInfo.pre_primary_sectin_avilable} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Board for Class 10th</label>
                                                        <select name="board_for_class_10th" value={viewModel.schoolInfo.board_for_class_10th} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Others">Others</option>
                                                            <option value="State">State</option>
                                                            <option value="CBSE">CBSE</option>
                                                            <option value="ICSE">ICSE</option>
                                                        </select>
                                                        {(viewModel.schoolInfoSubmit == true && viewModel.schoolInfo.board_for_class_10th == '') ? (<div className="text-danger">Board required </div>) : ''}
                                                    </div>
                                                </div>

                                                <ul className="list-inline wizard mb-0">
                                                    <li className="next list-inline-item float-end">
                                                        <a className="btn btn-secondary" onClick={viewModel.validateSchoolInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={viewModel.activeTab == 'addressInfo' ? 'tab-pane active show' : 'tab-pane'} id="viewModel.addressInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >State </label>


                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={viewModel.lstStates}
                                                            onChange={viewModel.handleStateChange}
                                                            value={viewModel.lstStates.length > 0 ? (viewModel.lstStates.find((option: any) => option.value == viewModel.addressInfo.state_id) || null) : null}
                                                            loadOptions={viewModel.loadState}
                                                        />

                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.state_id == 0) ? (<div className="text-danger">State required </div>) : ''}

                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >District</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={viewModel.lstDistricts}
                                                            onChange={viewModel.handleDistrictChange}
                                                            value={viewModel.lstDistricts.find((option: any) => option.value == viewModel.addressInfo.district_id) || null}
                                                            loadOptions={viewModel.loadDistrict}
                                                        />


                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.district_id == 0) ? (<div className="text-danger">District required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >City(Blocks)</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={viewModel.lstCities}
                                                            onChange={viewModel.handleCityChange}
                                                            value={viewModel.lstCities.find((option: any) => option.value == viewModel.addressInfo.city_id) || null}
                                                            loadOptions={viewModel.loadCity}
                                                        />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.city_id == 0) ? (<div className="text-danger">City required </div>) : ''}
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Cluster</label>

                                                        {/* <select name="cluster_id" value={viewModel.addressInfo.cluster_id} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Urban">Urban</option>
                                                            <option value="Semi-Urban">Semi-Urban</option>
                                                            <option value="Rural">Rural</option>
                                                        </select> */}
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={viewModel.lstCluster}
                                                            onChange={viewModel.handleClusterChange}
                                                            value={viewModel.lstCluster.find((option: any) => option.value == viewModel.addressInfo.cluster_id) || null}
                                                            loadOptions={viewModel.loadCluster}
                                                        />

                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.cluster_id == 0) ? (<div className="text-danger">Cluster required </div>) : ''}

                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Location(lat,lan)</label>



                                                        <input type="text" name="location" value={viewModel.addressInfo.location} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />

                                                        {/* <select name="location" value={viewModel.addressInfo.location} className="form-control" onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)}>
                                                            <option value="Latitude">Latitude</option>
                                                            <option value="Longitude">Longitude</option>
                                                        </select> */}
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Contact No</label>
                                                        <input type="text" name="contact_no" className="form-control" value={viewModel.addressInfo.contact_no} onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.contact_no == '') ? (<div className="text-danger">Contact required </div>) : ''}
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label >Address</label>
                                                        <input type="text" name="address" className="form-control" value={viewModel.addressInfo.address} onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.address == '') ? (<div className="text-danger">Address required </div>) : ''}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h4 >Login Details</h4>
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Email </label>
                                                        <input type="email" name="email" className="form-control" value={viewModel.addressInfo.email} onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.email == '') ? (<div className="text-danger">Email required </div>) : ''}
                                                        {((viewModel.addressInfo.email == '' || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(viewModel.addressInfo.email)) && viewModel.addressInfoSubmit == true) ? (<label className="m-0 text-danger">Invalid email.</label>) : ''}


                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label htmlFor="">Passowrd</label>
                                                        <input type="text" className="form-control" name="password" value={viewModel.addressInfo.password} onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.password == '') ? (<div className="text-danger">Password required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label htmlFor="">Re-Passowrd</label>
                                                        <input type="text" className="form-control" name="repassword" value={viewModel.addressInfo.repassword} onChange={(e) => viewModel.handleChange(e, viewModel.addressInfo.title)} />
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.repassword == '') ? (<div className="text-danger">Password required </div>) : ''}
                                                        {(viewModel.addressInfoSubmit == true && viewModel.addressInfo.password != viewModel.addressInfo.repassword) ? (<div className="text-danger">Password mismatch</div>) : ''}
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline">
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-secondary" onClick={viewModel.validateAddressInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => viewModel.back('schoolInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back</button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={viewModel.activeTab == 'resourceInfo' ? 'tab-pane active show' : 'tab-pane'} id="resourceInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >Head Teacher </label>
                                                        <input type="text" name="head_teacher" className="form-control" value={viewModel.resourceInfo.head_teacher} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Total Teachers </label>
                                                        <input type="number" name="total_teachers" className="form-control" value={viewModel.resourceInfo.total_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Pre Primary Teachers </label>
                                                        <input type="number" name="pre_primary_teachers" className="form-control" value={viewModel.resourceInfo.pre_primary_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Primary Teachers </label>
                                                        <input type="text" name="primary_teachers" className="form-control" value={viewModel.resourceInfo.primary_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Middle School Teachers </label>
                                                        <input type="text" name="middle_school_teachers" className="form-control" value={viewModel.resourceInfo.middle_school_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >High SchoolTeachers </label>
                                                        <input type="text" name="high_school_teachers" className="form-control" value={viewModel.resourceInfo.high_school_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Female Teacher</label>
                                                        <input type="number" name="female_teachers" className="form-control" value={viewModel.resourceInfo.female_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Male Teacher </label>
                                                        <input type="number" name="male_teachers" className="form-control" value={viewModel.resourceInfo.male_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Contract Teachers </label>
                                                        <input type="number" name="contract_teachers" className="form-control" value={viewModel.resourceInfo.contract_teachers} onChange={(e) => viewModel.handleChange(e, viewModel.resourceInfo.title)} />
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline mt-1">
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-secondary" onClick={viewModel.validateResourceInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => viewModel.back('addressInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={viewModel.activeTab == 'facilityInfo' ? 'tab-pane active show' : 'tab-pane'} id="viewModel.facilityInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >Total number of Class Rooms </label>
                                                        <input type="number" name="class_rooms" className="form-control" value={viewModel.facilityInfo.class_rooms} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Boys Toilet</label>
                                                        <input type="number" name="boys_toilet" className="form-control" value={viewModel.facilityInfo.boys_toilet} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Girls Toilet</label>
                                                        <input type="number" name="girls_toilet" className="form-control" value={viewModel.facilityInfo.girls_toilet} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Mid-day Meal Status / Info</label>
                                                        <select name="mid_day_meal" className="form-control" value={viewModel.facilityInfo.mid_day_meal} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Is School Residential</label>
                                                        <select name="is_school_residential" className="form-control" value={viewModel.facilityInfo.is_school_residential} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Residential Type</label>
                                                        <select name="residential_type" className="form-control" value={viewModel.facilityInfo.residential_type} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Not Applicable">Not Applicable</option>
                                                            <option value="Applicable">Applicable</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Electricity</label>
                                                        <select name="electricity" className="form-control" value={viewModel.facilityInfo.electricity} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            {/* <option value="" selected aria-readonly disabled>Select</option> */}
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Wall</label>
                                                        <select name="wall" className="form-control" value={viewModel.facilityInfo.wall} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Pucca">Pucca</option>
                                                            <option value="Thatched">Thatched</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Library</label>
                                                        <select name="library" className="form-control" value={viewModel.facilityInfo.library} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Playground</label>
                                                        <select name="playground" className="form-control" value={viewModel.facilityInfo.playground} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Total Books in Library</label>
                                                        <input type="number" name="books_in_library" className="form-control" value={viewModel.facilityInfo.books_in_library} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Drinking Water</label>
                                                        <select name="drinking_water" className="form-control" value={viewModel.facilityInfo.drinking_water} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Tap Water">Tap Water</option>
                                                            <option value="Boar Water">Boar Water</option>
                                                            <option value="Filter Water">Filter Water</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Ramps for Disabled</label>
                                                        <select name="ramps_for_disable" className="form-control" value={viewModel.facilityInfo.ramps_for_disable} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Canteen</label>
                                                        <select name="canteen" className="form-control" value={viewModel.facilityInfo.canteen} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Transport Facility</label>
                                                        <select name="transport_facility" className="form-control" value={viewModel.facilityInfo.transport_facility} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Available">Available</option>
                                                            <option value="Not Available">Not Available</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Computer Aided Learning Status</label>
                                                        <select name="computer_aided_learning" className="form-control" value={viewModel.facilityInfo.computer_aided_learning} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Available">Available</option>
                                                            <option value="Not Available">Not Available</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >If Available Number of Computers available</label>
                                                        <input type="number" name="computers" className="form-control" value={viewModel.facilityInfo.computers} onChange={(e) => viewModel.handleChange(e, viewModel.facilityInfo.title)} />
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline mt-1">
                                                    <li className="next list-inline-item ">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" onClick={viewModel.cancel}>Cancel</button>
                                                    </li>
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-success" onClick={viewModel.saveSchool}>Save <i className="mdi mdi-check me-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => viewModel.back('resourceInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close </button>
                            <button type="button" className="btn btn-primary">Save </button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div id="about-modal" className="modal fade" aria-labelledby="aboutLabel" data-bs-backdrop="static" aria-hidden="true" >
                <div className="modal-dialog modal-full-width">
                    <div className="modal-content">
                        <form action="" >
                            <div className="modal-header">
                                <h4 className="modal-title" id="aboutLabel">About Us</h4>
                                <button type="button" className="btn-close" id="aboutModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        {/* <div >{aboutus.about} </div> */}
                                        <ReactQuill
                                            theme="snow"
                                            modules={viewModel.modules}
                                            formats={viewModel.formats}
                                            placeholder="write your content ...."
                                            onChange={viewModel.handleProcedureContentChange}
                                            
                                            value={viewModel.aboutus.about}
                                            style={{ height: "520px" }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={viewModel.saveAboutus}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <div id="social-media" className="modal fade" aria-labelledby="socialMediaLabel" data-bs-backdrop="static" aria-hidden="true" >
                <div className="modal-dialog " style={{width:'40%',maxWidth:'none'}}>
                    <div className="modal-content">
                        <form action="" >
                            <div className="modal-header">
                                <h4 className="modal-title" id="socialMediaLabel">Social Media</h4>
                                <button type="button" className="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-4">
 

                                    <div className="input-container">
    <i className="mdi mdi-twitter icon"></i>
    <input className="input-field" type="text" placeholder="twitter" 
    
    name="twitter_url" value={viewModel.schoolInfo.twitter_url}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}
    
    />
  </div>

  <div className="input-container">
    <i className="mdi mdi-facebook icon"></i>
    <input className="input-field" type="text" placeholder="facebook" 
      name="facebook_url" value={viewModel.schoolInfo.facebook_url}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}
    
    
    />
  </div>

  <div className="input-container">
    <i className="mdi mdi-whatsapp icon"></i>
    <input className="input-field" type="text" placeholder="whatsapp" 
    name="whatsapp_url" value={viewModel.schoolInfo.whatsapp_url}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}
    
    
    />
  </div>

  <div className="input-container">
    <i className="mdi mdi-youtube icon"></i>
    <input className="input-field" type="text" placeholder="youtube"
    
    name="youtube_url" value={viewModel.schoolInfo.youtube_url}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}
    
    />
  </div>
  <div className="input-container">
    <i className="mdi mdi-instagram icon"></i>
    <input className="input-field" type="text" placeholder="instagram" 
    
    name="instagram_url" value={viewModel.schoolInfo.instagram_url}  onChange={(e) => viewModel.handleChange(e, viewModel.schoolInfo.title)}
    
    
    />
  </div>

  


                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={viewModel.saveSchool} data-bs-dismiss="modal">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <div id="social-fees" className="modal fade" aria-labelledby="socialMediaLabel" data-bs-backdrop="static" aria-hidden="true" >
                <div className="modal-dialog " style={{ width: '50%', maxWidth: 'none' }}>
                    <div className="modal-content">
                        <form action="" >
                            <div className="modal-header">
                                <h4 className="modal-title" id="socialMediaLabel">Fee and Class Teacher</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-4">

                                        {

                                            viewModel.lstSchoolFees.map((feeData: any, index: number) => {
                                                return (<div className="input-container" style={{ textAlign: "center" }}>
                                                    <label className="form-control" style={{ width: "20%", border: "0px" }} >{feeData.classes}  <b>Rs.</b></label>
                                                    
                                                    <input className="input-field" style={{ width: "20%" }} type="number" placeholder={feeData.classes + ' Fee'}

                                                        value={feeData.fee} onChange={(e) => viewModel.onFeeChange(e, index,'fee')}

                                                    /><label className="form-control" style={{ width: "20%", border: "0px" }} >Class Teacher</label>

                                                    <input className="input-field" style={{ width: "50%" }} type="text" placeholder={' class teacher'}

                                                        value={feeData.class_teacher} onChange={(e) => viewModel.onFeeChange(e, index,'class_teacher')}/>
                                                   
                                                  
                                                   
                                                </div>
                                                )

                                            })

                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={viewModel.saveFees} data-bs-dismiss="modal">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>


    );







}
export default HomeView;