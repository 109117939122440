import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useHistory } from "react-router-dom";
import { createCanvas, loadImage } from 'canvas';
import jpeg from 'jpeg-js';

export function Resourceviewmodel() {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const schoolRepository = SchoolRepository();
    const imagePath = schoolRepository.imagePath;
    const userStoredData = useSelector((state: AppState) => {
        //    / debugger; 
        return state.storeData.viewName == 'SchoolLogin' ? state.storeData.storeData : null;
    });





    const [lstResources, setResources] = useState({
        data: []
    })

    const [resource, setResourcedata] = useState({
        resource_id: 0,
        resource_name: '',
        resource_type: '',
        file_type: '',
        school_id: 0,
        sq_no: '',
        updated_date: ''
    })

    const [submit, setSubmit] = useState(false);
    const [lstSchool, setSchool] = useState<{ label: string; value: string }[] | []>([]);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setResourcedata((prev) => ({ ...prev, [name]: value }));
        }
        else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setResourcedata((prev) => ({ ...prev, [name]: value }));

        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };


    const getResources = async () => {
        debugger;
        try {

            const data: any = await schoolRepository.getResources({ 'school_id': userStoredData.school_id });
            setResources({
                data
            })
        }
        catch (error) {
            setResources({
                data: []
            })
        }
    }

    const getSchool = async () => {
        try {

            const data: any = await schoolRepository.getSchool();
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.school_name,
                value: item.school_id.toString(),
            }));

            setSchool(options);
        }
        catch (error) {
            console.error('Error fetching all options:', error);
        }
    }
    const btnLogOutClick = () => {
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData: {},
                viewName: 'SchoolLogin',
            },
        });

        navigate.push('/login');
    }
    useEffect(() => {
        try {
            if (typeof (userStoredData.school_id) == 'undefined' || (+userStoredData.school_id == 0)) {
                navigate.push('/login');
            }
        } catch (e) {
            navigate.push('/login');
        }
        getResources();
        getSchool();
    }, []);

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const filteredResources = lstResources.data.filter((resource: any) =>
        resource.resource_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const addUser = (e: React.FormEvent) => {
        debugger;
        e.preventDefault();

        setResourcedata({
            resource_id: 0,
            resource_name: '',
            resource_type: '',
            file_type: '',
            school_id: 0,
            sq_no: '',
            updated_date: ''
        });

    }

    const editResource = (e: any) => {
        debugger;
        setResourcedata(e);
    }

    const deleteResource = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await schoolRepository.deleteResource({ 'resource_id': e });

            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getResources();
            }

        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }

    }

    const handleSchoolChange = (selectedOption: any | null) => {
        debugger;
        setResourcedata(preResourceInfo => ({
            ...preResourceInfo,
            school_id: selectedOption ? selectedOption.value : '',
        }));
    };

    const loadSchool = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterSchool(inputValue);
        callback(filteredOptions);
    };

    const filterSchool = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstSchool.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstSchool;
        }
    };

    const saveResource = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();
        setSubmit(true);

        resource.school_id = userStoredData.school_id;
        // if (fileInputRef.current) {
        //     debugger;
        //     fileInputRef.current.value = '';
        //   }
        try {
            if (resource.resource_name != '' && resource.resource_type != '' && resource.school_id != 0 && (file || resource.file_type != '')) {
                debugger;

                if (file) {
                    let date = new Date();
                    let imageName = (resource.school_id) + date.getTime().toString();

                    resource.file_type = imageName;
                }
                setResourcedata(resource);

                const responseData: any = await schoolRepository.saveResource(resource);

                if (responseData.length > 0 && responseData[0].db_result > 0) {
                    debugger;
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    await uploadImage();
                    if (resource.resource_id > 0) {
                        debugger;

                        Swal.fire("Record updated successfully", "Record updated successfully", "success");

                    } else {
                        Swal.fire("Record saved successfully", "Record saved successfully", "success");
                    }

                    getResources();
                }
            }
        }
        catch (error) {
            console.log('Error making POST request:', error)
        }
    }



    const cancel = () => {
        setSubmit(false);
    }


    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    // const uploadImage = async () => {
    //     debugger;
    //     if (file) {
    //         debugger;
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('imageToSave', resource.file_type);


          


            
    //         try {

    //             const responseData: any = await schoolRepository.uploadImage(formData);
    //             window.location.reload();
    //             console.log('Image uploaded successfully:', responseData.imageUrl);
    //         }
    //         catch (error) {
    //             console.error('Error uploading image:', error);
    //         }

    //     }


    // }
    const uploadImage = async () => {
        
    
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                debugger;
              if (e.target && e.target.result) {
            //    const base64Image = e.target.result.toString().replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      
                try {
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('imageToSave', resource.file_type);
      
                  const responseData: any = await schoolRepository.uploadImage(formData);
                  window.location.reload();
                  console.log('Image uploaded successfully:', responseData.imageUrl);
                } catch (error) {
                  console.error('Error uploading image:', error);
                }
              }
            };
      
            reader.readAsDataURL(file);
          }


      };
    const sequenceChange = (d: any, e: number) => {
        debugger;
        let lstCont: any = lstResources.data;
        for (let i = 0; i < lstCont.length; i++) {
            if (lstCont[i].resource_id == d.resource_id) {
                lstCont[i].sq_no = (+e);
            }
        }
        let data = lstCont;
        setResources({
            data
        })
    }

    const updateSequence = async ()=>{
        debugger;
        let contentData = lstResources.data;
        let data = JSON.stringify(contentData);
        try {
            debugger;
            const responseData: any = await schoolRepository.saveResourceSequence(data);
            if (responseData[0].db_result > 0) {
                getResources();
            }
            Swal.fire("Record saved successfully", "Record saved successfully", "success");
        } catch (error) {
        }
    }   



    return {
        btnLogOutClick, imagePath, editResource, lstSchool, handleSchoolChange, resource, getResources, addUser, filteredResources, deleteResource, saveResource, cancel, handleChange, submit, fileInputRef, imageSrc, loadSchool, handleImageChange,
        sequenceChange,
        updateSequence
    }


}

