import { Announcementviewmodel } from "./Announcementviewmodel";

const Announcemtview:React.FC=()=>
 {
    const viewmodel = Announcementviewmodel();

    return (
        <div  >
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active"  onClick={viewmodel.btnLogOutClick}>  <i className="mdi mdi-lock me-2 btn-logout" > logout</i> </li>
                                    </ol>
                                </div>
                                <a className="btn btn-danger back-btn"  href="/">
                                <i className="mdi mdi-chevron-left fs-normal"> Back </i></a>  
                                <h4 className="page-title">Announcements </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={viewmodel.searchTerm} onChange={viewmodel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" >
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Announcement
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Announcements</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {viewmodel.filteredannounce.map((d: any, index: number) => (
                                                            <tr key={d.information}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.information}</td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => viewmodel.editAnnouncement(d)} ></i>
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => viewmodel.deleteannouncement(d.announcements_id)} ></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={viewmodel.saveAnnouncemts}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Announcement</h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={viewmodel.cancel} ></button>
                            </div>
                            <div className="modal-body">

                                <div className="col-md-12 mb-2">
                                    <label >Announcement</label>
                                     <textarea name="information" id="information"  className="form-control h-225"  value={viewmodel.announcemnts.information}  onChange={viewmodel.handleChange}></textarea>
                                    {<div className="text-danger">{viewmodel.validate.information}</div>}
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal"  >Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Announcemtview;