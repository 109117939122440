import { useEffect, useState } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository";
import 'react-quill/dist/quill.snow.css'; 
import { ActionType, AppState } from "../../store/Page.Actions";
import Swal from "sweetalert2";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
export function HomeViewModel()
{
    const dispatch = useDispatch();
    const navigate = useHistory();
    const repository=SchoolRepository();
    const imagePath=repository.imagePath;
const [schoolLoginInfo,setSchoolLoginInfo]=useState({address : "" ,contact_no : "", email : " " ,image : null, school_id : 0, school_name : "0", status : null, udise_code : "",expairy_date:"",validity:0});
const [activeTab, setActiveTab] = useState('schoolInfo');
//const [school,setSchoolDetails]=useState([]);
const [schoolResources,setSchoolResourcesDetails]=useState([]);
const [aboutus,setAboutUs]=useState({
    about: '',
    school_id: 0,
});
const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    
const [announcemnts,setAnnouncemnts]=useState([]);

const [file, setFile] = useState<File | undefined>(undefined);
const [schoolInfoSubmit, setschoolInfoSubmit] = useState(false);
 // States list
 const [lstStates, setStates] = useState<{ label: string; value: string }[] | []>([]);
 // Districts list
 const [lstDistricts, setDistricts] = useState<{ label: string; value: string }[] | []>([]);
 // Cities list
 const [lstCities, setCities] = useState<{ label: string; value: string }[] | []>([]);
 // Cluster list
 const [lstCluster, setCluster] = useState<{ label: string; value: string }[] | []>([]);

const [lstSchools, setSchools] = useState({
    data: []
})
const [schoolInfo, setSchoolInfo] = useState({
    title: 'schoolInfo',
    school_id: 0,
    udise_code: '',
    school_name: '',
    instruction_medium: '',
    school_type: '',
    classes: '',
    year_of_establishment: 0,
    school_area: '',
    school_shifted_to_new_place: '',
    management: '',
    building: '',
    pre_primary_sectin_avilable: '',
    board_for_class_10th: '',
    status: '',
    image:'',
    address:'',
    twitter_url:'',
    facebook_url:'',
    whatsapp_url:'',

    youtube_url:'',
    instagram_url:'',

})
const [addressInfo, setAddressInfo] = useState({
    title: 'addressInfo',
    state_id: 0,
    district_id: 0,
    city_id: 0,
    cluster_id: 0,
    location: '',
    contact_no: '',
    email: '',
    password: '',
    repassword: '',
    image:'',
    address:''
})
var modules = {
    toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }
        ],
        [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
};


const [resourceInfo, setResourceInfo] = useState({
    title: 'resourceInfo',
    head_teacher: 0,
    total_teachers: 0,
    pre_primary_teachers: 0,
    female_teachers: 0,
    male_teachers: 0,
    contract_teachers: 0,
    primary_teachers: 0,
    middle_school_teachers: 0,
    high_school_teachers: 0
})
const [facilityInfo, setFacilityInfo] = useState({
    title: 'facilityInfo',
    class_rooms: 0,
    boys_toilet: 0,
    girls_toilet: 0,
    mid_day_meal: '',
    is_school_residential: '',
    residential_type: '',
    electricity: '',
    wall: '',
    library: '',
    playground: '',
    books_in_library: 0,
    drinking_water: '',
    ramps_for_disable: '',
    canteen: '',
    transport_facility: '',
    computer_aided_learning: '',
    computers: 0,
    updated_date: ''
})
const editSchool = (e: any) => {
    debugger;
    // setFacilityInfo({...facilityInfo,  ...e,});

    setSchoolInfo({
        title: 'schoolInfo',
        school_id: e.school_id,
        udise_code: e.udise_code,
        school_name: e.school_name,
        instruction_medium: e.instruction_medium,
        school_type: e.school_type,
        classes: e.classes,
        year_of_establishment: e.year_of_establishment,
        school_area: e.school_area,
        school_shifted_to_new_place: e.school_shifted_to_new_place,
        management: e.management,
        building: e.building,
        pre_primary_sectin_avilable: e.pre_primary_sectin_avilable,
        board_for_class_10th: e.board_for_class_10th,
        status: e.status,
        image:e.image,
        address:e.address,

        twitter_url:e.twitter_url,
        facebook_url:e.facebook_url,
        whatsapp_url:e.whatsapp_url,
    
        youtube_url:e.youtube_url,
        instagram_url:e.instagram_url,
    })

    setAddressInfo({
        title: 'addressInfo',
        state_id: e.state_id,
        district_id: e.district_id,
        city_id: e.city_id,
        cluster_id: e.cluster_id,
        location: e.location,
        contact_no: e.contact_no,
        email: e.email,
        password: e.password,
        repassword: e.password,
        image:e.image,
        address:e.address,
    });

    setResourceInfo({
        title: 'resourceInfo',
        head_teacher: e.head_teacher,
        total_teachers: e.total_teachers,
        pre_primary_teachers: e.pre_primary_teachers,
        female_teachers: e.female_teachers,
        male_teachers: e.male_teachers,
        contract_teachers: e.contract_teachers,
        primary_teachers: e.primary_teachers,
        middle_school_teachers: e.middle_school_teachers,
        high_school_teachers: e.high_school_teachers,
    });

    setFacilityInfo({
        title: 'facilityInfo',
        class_rooms: e.class_rooms,
        boys_toilet: e.boys_toilet,
        girls_toilet: e.girls_toilet,
        mid_day_meal: e.mid_day_meal,
        is_school_residential: e.is_school_residential,
        residential_type: e.residential_type,
        electricity: e.electricity,
        wall: e.wall,
        library: e.library,
        playground: e.playground,
        books_in_library: e.books_in_library,
        drinking_water: e.drinking_water,
        ramps_for_disable: e.ramps_for_disable,
        canteen: e.canteen,
        transport_facility: e.transport_facility,
        computer_aided_learning: e.computer_aided_learning,
        computers: e.computers,
        updated_date: e.updated_date,
    });

    getDistrictByState(e.state_id);
    getCityByDistrict(e.district_id);
    getClusterByCity(e.city_id);
}

const getDistrictByState = async (e: string) => {
    try {
       
        const data = await repository.getDistrictByState({ 'state_id': e });
        const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.district_name,
            value: item.district_id.toString(),
        }));
        setDistricts(options)
    }
    catch (error) {
        console.error('Error fetching all options:', error);
    }
}

const getCityByDistrict = async (e: string) => {
    try {


        const data = await repository.getCityByDistrict({ 'district_id': e });
        
        const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.city_name,
            value: item.city_id.toString(),
        }));
        setCities(options)
    }
    catch (error) {
        console.error('Error fetching all options:', error);
    }
}

const getClusterByCity = async (e: string) => {
    debugger;
    try {
      
        debugger;
        const data = await repository.getClusterByCity({ 'city_id': e });
        debugger;
        const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.cluster_name,
            value: item.cluster_id.toString(),
        }));
        debugger;
        setCluster(options)
    }
    catch (error) {
        console.error('Error fetching all options:', error);
    }
}

const btnResorceClick=()=>
{
    navigate.push('/resources')

}
const validateSchoolInfo = () => {
    debugger;
    setschoolInfoSubmit(true);
    // setActiveTab('addressInfo');
    if (schoolInfo.udise_code != '' && schoolInfo.school_name != '' && schoolInfo.instruction_medium != '' && schoolInfo.school_type != '' && schoolInfo.classes != '' && schoolInfo.school_area != '' && schoolInfo.board_for_class_10th != '') {
        setActiveTab('addressInfo');
    }
}

const back = (e: string) => {
    debugger;
    setActiveTab(e);
}
const [addressInfoSubmit, setaddressInfoSubmit] = useState(false);
const [resourceInfoSubmit, setresourceInfoSubmit] = useState(false);
const validateAddressInfo = () => {
    debugger;
    setaddressInfoSubmit(true);
    // setActiveTab('resourceInfo')
    if (addressInfo.state_id != 0  && addressInfo.address !=''&&  addressInfo.district_id != 0 && addressInfo.city_id != 0 && addressInfo.cluster_id != 0 && addressInfo.contact_no != '' && addressInfo.email != '' && addressInfo.password != '' && addressInfo.repassword != '' && addressInfo.password == addressInfo.repassword
    && (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(addressInfo.email))
    
     {
        setActiveTab('resourceInfo')
    }
}

const validateResourceInfo = () => {
    debugger;
    setresourceInfoSubmit(true);
    setActiveTab('facilityInfo')
}


const handleSelectChange = (selectedOption: any | null) => {
    debugger;
    setSchoolInfo(prevSchoolInfo => ({
        ...prevSchoolInfo,
        classes: selectedOption ? selectedOption.value : '',
    }));
};

const handleStateChange = (selectedOption: any | null) => {
    debugger;
    setAddressInfo(preAddressInfo => ({
        ...preAddressInfo,
        state_id: selectedOption ? selectedOption.value : '',
    }));
    if (selectedOption.value > 0) {
        debugger;
        getDistrictByState(selectedOption.value);
    }
};

const handleDistrictChange = (selectedOption: any | null) => {
    debugger;
    setAddressInfo(preAddressInfo => ({
        ...preAddressInfo,
        district_id: selectedOption ? selectedOption.value : '',
    }));
    getCityByDistrict(selectedOption.value);
};

const handleCityChange = (selectedOption: any | null) => {
    debugger;
    setAddressInfo(preAddressInfo => ({
        ...preAddressInfo,
        city_id: selectedOption ? selectedOption.value : '',
    }));
    getClusterByCity(selectedOption.value);
};

const handleClusterChange = (selectedOption: any | null) => {
    debugger;
    setAddressInfo(preAddressInfo => ({
        ...preAddressInfo,
        cluster_id: selectedOption ? selectedOption.value : '',
    }));
    // getClusterByCity(selectedOption.value);
};

const saveSchool = async () => {
    debugger;
    try {
        debugger;
        var concatenatedInfoString = JSON.stringify({ ...schoolInfo, ...addressInfo, ...resourceInfo, ...facilityInfo });
       
        const responseData = await repository.saveSchool(concatenatedInfoString);
        debugger;
        if (responseData[0].db_result > 0) {
            cancel();
            const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
            if (buttonElement) {
                buttonElement.click();
            }

            if (schoolInfo.school_id > 0) {
                Swal.fire("Record updated successfully", "Record updated successfully", "success");
            } else {
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            }
            getSchoolDetails();
        } else {
            Swal.fire("No changes made", "No changes made", "warning");
        }

    } catch (error) {
    }
}

const cancel = () => {
    debugger;
    setSchoolInfo({
        title: 'schoolInfo',
        school_id: 0,
        udise_code: '',
        school_name: '',
        instruction_medium: '',
        school_type: '',
        classes: '',
        year_of_establishment: 0,
        school_area: '',
        school_shifted_to_new_place: '',
        management: '',
        building: '',
        pre_primary_sectin_avilable: '',
        board_for_class_10th: '',
        status: '',image:'',
        address:'',
        twitter_url:'',
        facebook_url:'',
        whatsapp_url:'',
    
        youtube_url:'',
        instagram_url:'',
    })

    setAddressInfo({
        title: 'addressInfo',
        state_id: 0,
        district_id: 0,
        city_id: 0,
        cluster_id: 0,
        location: '',
        contact_no: '',
        email: '',
        password: '',
        repassword: '',
        image:'',
        address:''
    })

    setResourceInfo({
        title: 'resourceInfo',
        head_teacher: 0,
        total_teachers: 0,
        pre_primary_teachers: 0,
        female_teachers: 0,
        male_teachers: 0,
        contract_teachers: 0,
        primary_teachers: 0,
        middle_school_teachers: 0,
        high_school_teachers: 0
    })

    setFacilityInfo({
        title: 'facilityInfo',
        class_rooms: 0,
        boys_toilet: 0,
        girls_toilet: 0,
        mid_day_meal: '',
        is_school_residential: '',
        residential_type: '',
        electricity: '',
        wall: '',
        library: '',
        playground: '',
        books_in_library: 0,
        drinking_water: '',
        ramps_for_disable: '',
        canteen: '',
        transport_facility: '',
        computer_aided_learning: '',
        computers: 0,
        updated_date: ''
    })

    setschoolInfoSubmit(false);
    setActiveTab('schoolInfo');
}

const schoolClasses: any = [
    { value: 'Pre-Primary Only', label: 'Pre-Primary Only' },
    { value: 'Pre-Primary to Primary', label: 'Pre-Primary to Primary' },
    { value: 'Pre-Primary To 4', label: 'Pre-Primary To 4' },
    { value: '1 To 4', label: '1 To 4' },
    { value: 'Pre-Primary To 7', label: 'Pre-Primary To 7' },
    { value: 'Primary To 7', label: 'Primary To 7' },
    { value: '1 To 7', label: '1 To 7' },
    { value: 'Pre-Primary To 10', label: 'Pre-Primary To 10' },
    { value: 'Primary To 10', label: 'Primary To 10' },
    { value: '1 To 10', label: '1 To 10' },
    { value: 'Pre-Primary To 12', label: 'Pre-Primary To 12' },
    { value: 'Primary To 12', label: 'Primary To 12' },
    { value: '1 To 12', label: '1 To 12' },
    { value: '8 To 10', label: '8 To 10' },
    { value: '8 To 12', label: '8 To 12' },
];


const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, title: string) => {
    debugger;
    if ('value' in e.target) {
        debugger;
        const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

        if (title == 'schoolInfo') {
            debugger;
            setSchoolInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'addressInfo') {
            debugger;
            setAddressInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'resourceInfo') {
            debugger;
            setResourceInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'facilityInfo') {
            debugger;
            setFacilityInfo((prev) => ({ ...prev, [name]: value }));
        }


    } else {
        debugger;
        const { name, value } = e.target as HTMLSelectElement;
        if (title == 'schoolInfo') {
            debugger;
            setSchoolInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'addressInfo') {
            debugger;
            setAddressInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'resourceInfo') {
            debugger;
            setResourceInfo((prev) => ({ ...prev, [name]: value }));
        }

        if (title == 'facilityInfo') {
            debugger;
            setFacilityInfo((prev) => ({ ...prev, [name]: value }));
        }
    }


}


const userStoredData = useSelector((state: AppState) =>
{
  
  

  return  typeof(state.storeData.SchoolLogin)!='undefined'?state.storeData.SchoolLogin:null;
}
);



// = useSelector((state: AppState) =>
// {
// //    / debugger; 
//   return  state.storeData.viewName=='SchoolLogin'?state.storeData.storeData:null;
// });



const btnLogOutClick=()=>{
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:{},
            viewName:'SchoolLogin',
        },
      });
    
    navigate.push('/login');
}




useEffect(()=>

{
    debugger;
    try{
if(typeof (userStoredData.school_id)=='undefined' || (+userStoredData.school_id==0))
{
    navigate.push('/login');
}}catch(e)
{
    navigate.push('/login');
}
    setSchoolLoginInfo(userStoredData);
    getStates();
 
},[]
)

const getStates = async () => {
    try {
         
        const data = await  repository.getStates()
        const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.state_name,
            value: item.state_id.toString(),
        }));
        setStates(options)
    }
    catch (error) {
        console.error('Error fetching all options:', error);
    }
}
const getSchoolDetails=async ()=>
{


    const data:any = await repository.getSchoolDetBySchoolId({school_id:schoolLoginInfo.school_id});
   // setSchoolDetails(data)
    setSchools(data)
    editSchool(data[0]);

    
    setAboutUs({about: data[0].about,
        school_id: schoolLoginInfo.school_id});
}



const getSchoolResources=async ()=>
{


    const data:any = await repository.getResources({school_id:schoolLoginInfo.school_id});
    setSchoolResourcesDetails(data)
}
const getAnnouncements=async ()=>
{


    const data:any = await repository.getAnnouncements({school_id:schoolLoginInfo.school_id});
    setAnnouncemnts(data)
}
const filterSchoolClasses = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return schoolClasses.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return schoolClasses;
    }
};
const loadSchoolClasses = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterSchoolClasses(inputValue);
    callback(filteredOptions);
};

const loadState = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterState(inputValue);
    callback(filteredOptions);
};

const filterState = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstStates.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstStates;
    }
};

const loadDistrict = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterDistrict(inputValue);
    callback(filteredOptions);
};

const filterDistrict = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstDistricts.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstDistricts;
    }
};

const loadCity = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterCity(inputValue);
    callback(filteredOptions);
};

const filterCity = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstCities.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstCities;
    }
};
const loadCluster = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterCluster(inputValue);
    callback(filteredOptions);
};

const filterCluster = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstCluster.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstCluster;
    }
};
const fileInputRef = React.createRef<HTMLInputElement>();

const changeImage=()=>
{
debugger;
    if (fileInputRef.current) {
        fileInputRef.current.click();
      }
}

    
const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    debugger;
    if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
        debugger;
        const selectedFile = e.target.files[0];

        if(selectedFile.size > 1024 * 1024){
          Swal.fire("Opps", "Max Image size is 1MB ", "error");
        }
        else{
           /// file=selectedFile;
          await  setFile(selectedFile);
            
            const reader = new FileReader();
            reader.onloadend = () => {
            setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
            debugger;
            await uploadImage(selectedFile);
        }
    }
};


const uploadImage = async (selectedFile:any) =>{
    debugger;
    if (selectedFile) {
        debugger;
        const formData = new FormData();
        formData.append('file', selectedFile);
        let date = new Date();
        
     //   addressInfo.image=  (schoolInfo.udise_code + date.getTime().toString())
       // setAddressInfo(addressInfo);
        formData.append('imageToSave', (schoolLoginInfo.image?schoolLoginInfo.image:schoolLoginInfo.udise_code));
        try {

            debugger;
            const response:any = await repository.uploadImage(formData);
            debugger;
            if (response.ok) {
                debugger;
            //  /   const responseData = await response.json();
                window.location.reload();
              //  saveSchool();
              //  navigate('/registrationsuccess');
               // console.log('Image uploaded successfully:', responseData.imageUrl);
              } else {
                console.error('Failed to upload image. Server returned:', response.status);
              }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }else{
        //navigate('/registrationsuccess');
      }
    }

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const handleProcedureContentChange = (content: any) => {
        debugger;
        aboutus.about = content;
        aboutus.school_id=schoolLoginInfo.school_id;
        setAboutUs(aboutus);
    };
const btnAnnouncementClick=()=>
{
    navigate.push('/announcement');

    
}




const btnContentClick=()=>
{
    navigate.push('/content');

    
}

    const saveAboutus = async () => {
        debugger;
      
        const responseData = await repository.saveAboutus(aboutus)
debugger;
        if (responseData[0].db_result > 0) {
            setAboutUs({
                school_id: 0,
                about: ''
            })

            const buttonElement = document.getElementById('aboutModal') as HTMLButtonElement | null;
            if (buttonElement) {
                buttonElement.click();
            }

            Swal.fire("Success", "About us updated successfully", "success");
            
        }
    }
const [lstSchoolFees,setSchoolFees]=useState([]);



const getSchoolFees=async ()=>{
    setSchoolFees([]);
      await repository.viewSchoolFees({school_id:schoolLoginInfo.school_id}).then((data:any)=>
      {
debugger;

// data.map((d:any)=>
// {
//     d.school_id=schoolLoginInfo.school_id;

// })
// for(let i=0;i<data.length;i++)
// {

   
// }


        setSchoolFees(data);
      })
}

const btnRenualClick=()=>
{

    
    saveOrder();

}

const saveOrder=async ()=>
{
    await repository.saveOrder({school_id:userStoredData.school_id,price_id:0}).then((result:any)=>
    {

        if(result.length>0 && result[0].db_result>0)
        {
debugger;
            
            dispatch({
                type: ActionType.STORE,
                payload: {
                  storeData: {'order_id':'S-'+result[0].order_id,'amount':result[0].amount,'school_id':result[0].school_id},
                  viewName: 'SchoolPaymentOrder',
                },
              });
           // Swal.fire("Success", "Fees updated successfully", "success");
           navigate.push('/paymentprocess');
        }

debugger;
       
    })

}

 
const onFeeChange=(e:any,index:number,fld:any)=>
{

    let lstFee:any=[...lstSchoolFees];

    lstFee[index][fld]=e.target.value;
 


    setSchoolFees(lstFee);
}

const saveFees=async ()=>
{
    await repository.saveSchoolFees(lstSchoolFees).then((result:any)=>
    {
        debugger;

        if(result.length>0 && result[0].db_result>0)
        {
            Swal.fire("Success", "Fees updated successfully", "success");

        }

debugger;
       
    })


}
const btnProducts=()=>
{
    navigate.push('/school-products');

    
}
    return {btnProducts,saveFees,onFeeChange,getSchoolFees,lstSchoolFees,handleImageChange,fileInputRef,changeImage,schoolResources,aboutus,getSchoolDetails,getSchoolResources,getAnnouncements,announcemnts,schoolLoginInfo,imagePath,schoolInfo
    ,activeTab
    ,cancel
    ,handleChange
    ,schoolInfoSubmit
    ,schoolClasses
    ,handleSelectChange
    ,loadSchoolClasses
    ,validateSchoolInfo
    ,lstStates
    ,handleStateChange
    ,loadState
    ,addressInfoSubmit
    ,addressInfo
    ,lstDistricts
    ,handleDistrictChange
    ,loadDistrict
    ,lstCities
    ,handleCityChange
    ,loadCity
    ,lstCluster
    ,handleClusterChange
    ,loadCluster
    ,validateAddressInfo
    ,back
    ,resourceInfo
    ,validateResourceInfo
    ,facilityInfo
    ,saveSchool
    ,btnResorceClick
    ,modules
    ,formats
    ,btnRenualClick
    ,handleProcedureContentChange
   ,saveAboutus
   ,btnAnnouncementClick
   ,btnLogOutClick
   ,btnContentClick
    }
}

