import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useHistory } from "react-router-dom";

export function Contentmodel(){
    const dispatch = useDispatch();
    const [schoolLoginInfo,setSchoolLoginInfo]=useState({address : "" ,contact_no : "", email : " " ,image : null, school_id : 0, school_name : "0", status : null, udise_code : ""});
    const navigate = useHistory();
    const schoolRepository = SchoolRepository();

    const [lstContents, setContents] = useState({
        data: []
    })

    const [lstFilterContents, setFilterContents] = useState(
        []
    )

    const [contents, setcontents] = useState({
        content_id: 0,
        sq_no: 0,
        title: '',
        school_id:  schoolLoginInfo.school_id ,
        content: ''
    })

    const [validate, setValidate] = useState({
        content_id: 0,
        sq_no: 0,
        title: '',
        school_id: 0,
        content: ''
    })
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e: any) => {
        debugger;
        let filteredcontent = lstContents.data.filter((contents: any) =>
        contents.title.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterContents(filteredcontent);
        setSearchTerm(e.target.value);
    };
    const filteredcontent = lstFilterContents.slice();

    const cancel = () => {
        debugger;

        setcontents({
            content_id: 0,
            sq_no: 0,
            title: '',
            school_id: 0,
            content: ''
        })

        setValidate({
            content_id: 0,
            sq_no: 0,
            title: '',
            school_id: 0,
            content: ''
        })


    }
    const userStoredData = useSelector((state: AppState) =>
    {
    //    / debugger; 
      return  state.storeData.viewName=='SchoolLogin'?state.storeData.storeData:null;
    });
    
    
    const btnLogOutClick=()=>{
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData:{},
                viewName:'SchoolLogin',
            },
          });
        
        navigate.push('/login');
    }

    useEffect(() => {
        //debugger;
        // getCities();
        try{
            if(typeof (userStoredData.school_id)=='undefined' || (+userStoredData.school_id==0))
            {
                navigate.push('/login');
            }}catch(e)
            {
                navigate.push('/login');
            }
        setSchoolLoginInfo(userStoredData);
        schoolLoginInfo.school_id=userStoredData.school_id;
        getContents();

    }, []);




    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setcontents((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setcontents((prev) => ({ ...prev, [name]: value }));
        }
    }


    const saveContents = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!contents.title.trim()) {
            newValidate.title = "Announcements  required"
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                contents.school_id=schoolLoginInfo.school_id;
                const responseData: any = await schoolRepository.saveContent(contents);

                if (responseData[0].db_result > 0) {
                    cancel();

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }
                    getContents();
                    //getCities()
                    Swal.fire("Record saved successfully", "Record saved successfully", "success");
                }else{
                    debugger;
                    if (responseData[0].db_result == -3) {
                        Swal.fire("Record exists", "Record exists", "warning");
                    }
                    else{
                        Swal.fire("Failed", "Failed", "error");

                    }

                }
                
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }


    const getContents = async () => {
        setFilterContents([]);
        debugger;
        try {
            const data: any = await schoolRepository.getContents({ 'school_id': schoolLoginInfo.school_id });

            setContents({
                data

            })
            setFilterContents(data)
            debugger;

        }
        catch (error) {
            setContents({
                data: []
            })
        }
    }

    const editContent = (e: any) => {
        debugger;
        setcontents(e);
    }

    const deleteContent = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData :any= schoolRepository.deleteContent({ 'Content_id': e });
            console.log(responseData)
            Swal.fire("Record Deleted successfully", "", "info");
           getContents();
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }


    const handleProcedureContentChange = (content: any) => {
        debugger;
        contents.content = content;
        contents.school_id=schoolLoginInfo.school_id;
        setcontents(contents);
    };

    const sequenceChange = (d:any , e: number)=>{
        debugger;
        let lstCont :any = lstContents.data;
        for(let i=0;i<lstCont.length;i++){
            if(lstCont[i].content_id == d.content_id){
                lstCont[i].sq_no = (+e);
            }
        }
        let data = lstCont;
        setContents({
            data
        })
    }

    const updateSequence = async ()=>{
        debugger;
        let contentData = lstContents.data;
        contentData.forEach(function(x:any){ delete x.content });
        let data = JSON.stringify(contentData);
        try {
            debugger;
            contents.school_id=schoolLoginInfo.school_id;
            const responseData: any = await schoolRepository.saveContentSequence(data);
            if (responseData[0].db_result > 0) {
                getContents();
            }
            Swal.fire("Record saved successfully", "Record saved successfully", "success");
        } catch (error) {
        }
    }   

    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];


    return {btnLogOutClick, saveContents, getContents,deleteContent, handleChange,cancel,editContent,handleSearch, handleProcedureContentChange,
        filteredcontent,
        searchTerm, validate, contents , modules,formats ,updateSequence,sequenceChange}
}