import { Contentmodel } from "./Contentmodel";
import ReactQuill from "react-quill";



const Content:React.FC=()=>
 {
    const contentmodel = Contentmodel();

    return (
        <div  >
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active"  onClick={contentmodel.btnLogOutClick}>  <i className="mdi mdi-lock me-2 btn-logout" > logout</i> </li>
                                    </ol>
                                </div>
                                <a className="btn btn-danger back-btn"  href="/">
                                <i className="mdi mdi-chevron-left fs-normal"> Back </i></a>  
                                <h4 className="page-title">Content </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={contentmodel.searchTerm} onChange={contentmodel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" >
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Content
                                            </a>

                                            <a className="btn btn-primary mb-2 mx-3" onClick={()=>contentmodel.updateSequence()}>
                                                Update
                                            </a>

                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Title</th>
                                                            {/* <th>Content</th> */}
                                                            <th>sq no</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contentmodel.filteredcontent.map((d: any, index: number) => (
                                                            <tr key={d.title}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.title}</td>
                                                                {/* <td>{d.content}</td> */}
                                                                <td>
                                                                    {/* {d.sq_no} */}
                                                                <input type="number" name="tsq_no" id="tsq_no" className="form-control" value={d.sq_no} 
                                                                onChange={(e:any)=>contentmodel.sequenceChange(d,e.target.value)} style={{width: "100px"}}/>
                                                                </td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => contentmodel.editContent(d)} ></i>
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => contentmodel.deleteContent(d.content_id)} ></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-full-width">
                    <div className="modal-content">
                        <form onSubmit={contentmodel.saveContents}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Content</h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={contentmodel.cancel} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label >Title</label>

                                    <select name="title" value={contentmodel.contents.title} className="form-control" onChange={contentmodel.handleChange}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="About School">About School</option>
                                                            <option value="Academic Programs">Academic Programs</option>
                                                            <option value="Extra Curricular Activities">Extra Curricular Activities</option>
                                                            <option value="Faculty information">Faculty information</option>
                                                            <option value="Facilities & Infrastructure">Facilities & Infrastructure</option>


                                                            <option value="Achievements & Recognitions">Achievements & Recognitions</option>
                                                            <option value="Parent Testomonials">Parent Testomonials</option>
                                                            

                                                        </select>

                                     {/* <input type="text" className="form-control" name="title" id="title" value={contentmodel.contents.title}  onChange={contentmodel.handleChange}/> */}
                                    {<div className="text-danger">{contentmodel.validate.title}</div>}
                                </div>

                                <div className="col-md-6 mb-2">
                                    <label >Sq no</label>
                                     <input type="number" className="form-control" 
                                     name="sq_no" 
                                     id="sq_no" 
                                     value={contentmodel.contents.sq_no} 
                                      onChange={contentmodel.handleChange}/>
                                </div>

                                <div className="col-md-12 mb-4">
                                    <label>Content</label>
                                    <ReactQuill
                                        theme="snow"
                                        modules={contentmodel.modules}
                                        formats={contentmodel.formats}
                                        placeholder="write your content ...."
                                        onChange={contentmodel.handleProcedureContentChange}
                                        style={{ height: "320px" }}
                                        value={contentmodel.contents.content}
                                    />
                                </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal"  >Close </button>
                                <button type="submit" className="btn btn-primary"  data-bs-dismiss="modal" >Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Content;