import { useSelector } from "react-redux";
import { AppState } from "../../store/Page.Actions";
import { useEffect, useState } from "react";

export function PaymentGatewayModel()
{
    const paymentOrder = useSelector((state: AppState) =>
    {
      
      
    
      return  typeof(state.storeData.SchoolPaymentOrder)!='undefined'?state.storeData.SchoolPaymentOrder:null;
    }
    );

    const [paymentGatewayUrl,setPaymentGatewayUrl]=useState('');
    useEffect(()=>
    {
        if(paymentOrder!=null)
        {
            debugger;
            setPaymentGatewayUrl("https://payments.educationtodayindia.com/HDFCTest?order_id="+paymentOrder.order_id+"&customer_id=S-"+paymentOrder.school_id+"&amount="+paymentOrder.amount+"&return=https://schooladmin.educationtodayindia.com/orderresult");

        }
    },[alert]);

    return{paymentGatewayUrl}
}
    